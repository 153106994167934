import DateInput from "../../../components/common/DateInput";
import FileInput from "../../../components/common/FileInput";
import { useContext, useEffect, useMemo, useState } from "react";
import AddBenificialModel from "../../../components/modals/AddBeneficialModal";
import InputValuesContext from "../../../contexts/InputValuesContext";
import { ListInputItem } from "../../../components/common/ListInputItem";
import EditTableModal from "../../../components/EditTableModal";
import { extractXLSXCells } from "../../../utils/csv";
import { transpose } from "../../../utils/array";
import { isFileType } from "../../../helpers/helper";
import { AiFillDelete } from "react-icons/ai";
import { ClauseEntity, SubClauseEntity } from "../../../domain/entities";
import { Control, FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";
import { BeneficialEntity } from "../../../domain/entities";
import { useTranslation } from "../../../contexts/TranslationProvider";
import { DocumentClient } from "../../../services/api/DocumentClient";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { PropertyParam, SegmentedClauseParam } from "../../../domain/types/ClauseParams";
import CustomPropertiesContext from "../../../contexts/CustomPropertiesContext";
import CustomCombobox from "../../../components/common/CustomCombobox";
import { evaluateParamConstraints, evaluateParamFormula } from "../../../domain/Form";
import { getAllParams } from "../../../domain/ContractTemplate";
import { validNumberInput } from "../../../domain/validators";
import { CustomPropertyEntity } from "../../../domain/entities/CustomProperty";
import { isValidInputValue } from "../../../helpers/CompletionPercentage";

function ParameterInput({
  param,
  clauseId,
  subClauseId,
  control,
  currentValue,
  handleInputChange,
  register,
  errors,
  isEditing,
}:
  {
    param: SegmentedClauseParam;
    clauseId: ClauseEntity['id'];
    subClauseId: SubClauseEntity['id'];
    control: Control<FieldValues, any>;
    currentValue: any;
    handleInputChange: (
      e: any,
      paramName: string,
      clauseId: ClauseEntity['id'],
      subClauseId: SubClauseEntity['id'],
      fromFormula?: boolean,
      scrollOn?: boolean
    ) => void;
    register: UseFormRegister<FieldValues>;
    errors: FieldErrors<FieldValues>;
    isEditing: boolean;
  }) {
  const { t, language } = useTranslation();

  const currentTranspose = !!currentValue?.[0]
  let currentCells = param.type == "csv" ? currentValue?.[1] ?? [] : []

  currentCells = currentTranspose ? transpose(currentCells) : currentCells
  const [showBenificialModel, setShowBenificialModel] = useState(false);
  const [beneficialToUpdate, setBeneficialToUpdate] = useState<BeneficialEntity>(null);

  const [showTableModal, setShowTableModal] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [csvCells, setCsvCells] = useState(currentCells);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const apiClient = useApiClientWithLoading();
  const documentClient = new DocumentClient(apiClient)
  const { inputValues, beneficialsMap, setBeneficialsMap, fileNames, contractTemplate } = useContext(InputValuesContext);
  const fieldName = `${clauseId}_${subClauseId}_${param.name}`;
  const [isModalOpen, setIsModalOpen] = useState(false);

  let formulaValue = currentValue;
  if ((param.type == 'number' || param.type == 'string') && param.formula) {
    formulaValue = evaluateParamFormula(param, inputValues, getAllParams(contractTemplate));
  }

  useEffect(() => {
    if ((param.type == 'number' || param.type == 'string') && param.formula) {
      formulaValue = evaluateParamFormula(param, inputValues, getAllParams(contractTemplate));
      handleInputChange(
        { target: { value: formulaValue } },
        param.name,
        clauseId,
        subClauseId,
        true
      );
    }
  }, [formulaValue])

  const [isOpen, setIsOpen] = useState(false)
  const { properties, propertiesGroup } = useContext(CustomPropertiesContext)
  useEffect(() => {
    register(fieldName, { required: true });
  }, [register, fieldName]);

  const onCSVInputChange = (file: File) => {
    if (file) {
      extractXLSXCells(file, (cells) => {
        setCsvCells(cells);
        handleInputChange(
          { target: { value: [false, cells, file.name] } },
          param.name,
          clauseId,
          subClauseId
        );
      });
    } else {
      setCsvCells([]);
      handleInputChange(
        { target: { value: null } },
        param.name,
        clauseId,
        subClauseId
      );
    }
  };
  const onTranspose = (value: boolean) => {
    console.log(value);

    handleInputChange(
      {
        target: {
          value: [
            value,
            value ? transpose(csvCells) : csvCells,
            currentValue[2],
          ],
        },
      },
      param.name,
      clauseId,
      subClauseId
    );
  };
  const constraintResults = (param.type === 'number' || param.type === 'string' || param.type === 'date') ? evaluateParamConstraints(param, inputValues, getAllParams(contractTemplate)) : null;

  let propertyOptions: CustomPropertyEntity[] | null = useMemo(() => {
    if (param.type != 'property')
      return []
    let propertyOptions = propertiesGroup[param.args.tag] ?? []
    if (param.args.parentParam) {
      const parentValue = inputValues[param.args.parentParam]
      if (!parentValue) {
        return []
      }
      const params = getAllParams(contractTemplate)
      const parentParam: PropertyParam = params.find(p => p.type == 'property' && p.name == param.args.parentParam) as any
      if (!parentValue) {
        console.error(param);
        return []
      }
      const parentProperty = properties.find(cp => cp.tag == parentParam.args.tag && cp.value == parentValue)
      if (!parentValue) {
        console.error(param);
        return []
      }
      propertyOptions = propertyOptions.filter((option) => option.parentPropertyId == parentProperty.id)
    }
    return propertyOptions
  }, [inputValues])

  const isValidInput = isValidInputValue(param, currentValue)
  const progressElement = isValidInput ? (
    <div className="input-progress-done  ms-2">
      {t("pages.editionContract.complete")}
    </div>
  ) : (
    <div className="input-inprogres ms-2">
      {t("pages.editionContract.inProgress")}
    </div>
  )
  switch (param.type) {
    // Inside the switch block of ParameterInput function
    case 'beneficial':
      return (
        <>
          <button
            type="button"
            className="benificial-button"
            onClick={() => setShowBenificialModel(true)}
          >
            {param.label}
          </button>
          {showBenificialModel && (
            <AddBenificialModel
              onClose={() => setShowBenificialModel(false)}
              beneficialsMap={beneficialsMap}
              setBeneficialsMap={setBeneficialsMap}
              currentBenificialData={beneficialsMap[currentValue] || {}}
              onSave={(beneficialId) => {
                handleInputChange(
                  { target: { value: beneficialId } },
                  param.name,
                  clauseId,
                  subClauseId
                );
                setShowBenificialModel(false);
              }}
              beneficialTypes={param.args?.beneficialTypes ?? ['Company', 'Person']}
            />
          )}
        </>
      );
    case 'beneficial[]':
      const beneficialsId: BeneficialEntity['id'][] = Array.isArray(currentValue) && currentValue || []
      const beneficials = beneficialsId.map(id => beneficialsMap[id]).filter(b => b)

      return (
        <div
          className={`d-flex flex-column ${currentValue ? "green-border" : "grey-border"
            }`}
        >
          <div className="d-flex align-items-start justify-content-between py-2 px-2">
            <label htmlFor={param.name} className="ms-2">
              {param.label}
            </label>
            {progressElement}
          </div>
          {beneficials?.length > 0 && (<div className="list-beneficial">
            {beneficials.map((beneficial, idx) => (
              <div key={`${beneficial.id}-${idx}`} className="item">

                <div onClick={() => setBeneficialToUpdate(beneficial)} className="beneficial-name">
                  {beneficial.name}
                </div>
                <div className="d-flex justify-content-between">
                  <div className={`type-tag`}>
                    {beneficial.type === "Person" && t("pages.editionContract.popups.benificial.type1")
                      || beneficial.type === "Company" && t("pages.editionContract.popups.benificial.type2")
                      || beneficial.type === "Minor" && t("pages.editionContract.popups.benificial.type3")
                    }
                  </div>
                  <AiFillDelete
                    style={{
                      marginRight: "0.5rem",
                      color: "#e5142f",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      let newValue = beneficialsId.filter(
                        (id) => id != beneficial.id
                      );
                      handleInputChange(
                        { target: { value: newValue } },
                        param.name,
                        clauseId,
                        subClauseId
                      );
                    }}
                  />
                </div>
              </div>
            ))}
          </div>)}
          {beneficialToUpdate && (
            <AddBenificialModel
              // listBeneficials={beneficials}
              onClose={() => setBeneficialToUpdate(null)}
              beneficialsMap={beneficialsMap}
              setBeneficialsMap={setBeneficialsMap}
              currentBenificialData={beneficialToUpdate}
              onSave={(beneficialId) => {
                setBeneficialToUpdate(null);
              }}
              beneficialTypes={param.args?.beneficialTypes ?? ['Company', 'Person']}
            />
          )}
          <div
          >
            <button
              type="button"
              className="benificial-button add-list-beneficials"
              onClick={() => {
                setShowBenificialModel(true);
              }}
            >
              {t("pages.editionContract.addBeneficial")}
            </button>
            {showBenificialModel && (
              <AddBenificialModel
                listBeneficials={beneficials}
                beneficialsMap={beneficialsMap}
                setBeneficialsMap={setBeneficialsMap}
                onSave={(beneficialId) => {
                  handleInputChange(
                    { target: { value: [...beneficialsId, beneficialId] } },
                    param.name,
                    clauseId,
                    subClauseId
                  );
                  setShowBenificialModel(false);
                }}
                onClose={() => setShowBenificialModel(false)}
                beneficialTypes={param.args?.beneficialTypes ?? ['Company', 'Person']}
              />
            )}

          </div>
        </div>
      );
    case 'table':
      const currentTableValues: (string | Date | number)[][] = inputValues?.[param.name] || [];
      return (
        <>
          <button
            type="button"
            className="benificial-button"
            onClick={() => setShowTableModal(true)}
          >
            {param.label}
          </button>
          {showTableModal && (
            <EditTableModal
              param={param}
              onClose={() => setShowTableModal(false)}
              currentTableValues={currentTableValues}
              onSave={(tableData) => {
                handleInputChange(
                  { target: { value: tableData } },
                  param.name,
                  clauseId,
                  subClauseId
                );
                setShowBenificialModel(false);
              }}
            />
          )}
        </>
      );
    case 'property':

      return (
        <>
          <label htmlFor={param.name}>{param.label}</label>
          <div style={{ width: "95%" }}>
            <CustomCombobox
              isOpen={isOpen}
              label={param.label}
              onChange={(value: string) => {
                handleInputChange(
                  { target: { value: value } },
                  param.name,
                  clauseId,
                  subClauseId
                )
              }}
              fullWidth
              options={propertyOptions.map(p => p.value)}
              setIsOpen={setIsOpen}
              value={currentValue}
            />
          </div>
        </>
      );
    case 'boolean':
      return (
        <div className="d-flex flex-column  gap-2  py-2 justify-content-between checkbox-input-container align-items-center form-group">
          <div className="d-flex flex-column ">
            <label htmlFor={param.name}>{param.label}</label>
          </div>
          <div className="radio-group boolean-group">
            <label className="form-check-label">
              <input
                type="radio"
                value="true"
                checked={currentValue === true}
                className={`form-check-input boolean-check ${currentValue === true && "checked-input"}`}
                onChange={(e) => {
                  handleInputChange(
                    { target: { value: true } },
                    param.name,
                    clauseId,
                    subClauseId,
                  );
                }}
                name={param.name}
              />
              {t("pages.editionContract.yes")}
            </label>
            <label className="form-check-label">
              <input
                type="radio"
                value="false"
                checked={currentValue === false}
                className={`form-check-input boolean-check ${currentValue === false && "checked-input"}`}
                onChange={(e) => {
                  handleInputChange(
                    { target: { value: false } },
                    param.name,
                    clauseId,
                    subClauseId,
                  );
                }}
                name={param.name}
              />
              {t("pages.editionContract.no")}
            </label>
          </div>
        </div>
      );
    case 'date':
      return (
        <div>
          <div
            className={`d-flex flex-column ${currentValue ? "green-border" : "grey-border"
              }`}
            style={{ marginBottom: calendarOpen && "300px" }}
          >
            <label htmlFor={param.name} className="ms-1">
              {param.label ?? "JJ/MM/AAAA"}
            </label>
            <div className="d-flex gap-2 align-items-center">
              {progressElement}
              <div className="w-100">
                <DateInput
                  name={`${clauseId}_${subClauseId}_${param.name}`}
                  // label={param.label}
                  control={control}
                  setCalendarOpen={setCalendarOpen}
                  defaultValue={currentValue}
                  onChange={(date) => {
                    if (date && !isNaN(date.getTime())) {
                      const formattedDate = date.getFullYear() + '-' +
                        String(date.getMonth() + 1).padStart(2, '0') + '-' +
                        String(date.getDate()).padStart(2, '0');
                      handleInputChange(
                        {
                          target: { value: formattedDate },
                        },
                        param.name,
                        clauseId,
                        subClauseId
                      );
                    }
                    setCalendarOpen(false)
                  }}
                />
              </div>
            </div>
          </div>
          {
            constraintResults && constraintResults.filter(c => !c[1]).length > 0 && <>
              {constraintResults.map((result, idx) => {
                return !result[1] ? <div key={idx} className="error-message-dialog">{result[0]}</div> : ""
              })}</>
          }
        </div>
      );
    case 'file':
      const displayFile = currentValue && (isFileType(currentValue) ? currentValue : { name: fileNames[currentValue] });
      const fileId = currentValue && Number(currentValue) || undefined
      return (
        <div className={`${currentValue ? "green-border" : "grey-border"}`}>
          <label htmlFor={param.name} className="ps-1">
            {param.label}
          </label>
          <div className="d-flex gap-2">
            {progressElement}
            <div className="w-100 position-relative">
              <FileInput
                {...register(fieldName, { required: true })}
                onChange={(file) => {
                  handleInputChange(
                    {
                      target: { value: file },
                    },
                    param.name,
                    clauseId,
                    subClauseId
                  );
                }}
                id={fileId}
                value={displayFile}
                borderClassName={displayFile ? "green-border" : "grey-border"}
                onOpenModal={() => { setIsModalOpen(!isModalOpen) }}
                openModal={isModalOpen}
              />
            </div>
          </div>
        </div>
      );

    case 'csv':
      return (
        <div className={`${currentValue ? "green-border" : "grey-border"}`}>
          <label htmlFor={param.name} className="ps-1">
            {param.label}
          </label>

          <div className="d-flex gap-2">
            {progressElement}
            {currentValue && (
              <div className="switch w-10">
                <input
                  type="checkbox"
                  id={`toggle_${clauseId}_${subClauseId}`}
                  onChange={(e) => onTranspose(e.target.checked)}
                />
                <label htmlFor={`toggle_${clauseId}_${subClauseId}`}></label>
              </div>
            )}
            <div className="w-100">
              <FileInput
                {...register(fieldName, { required: true })}
                onChange={onCSVInputChange}
                value={currentValue ? { name: currentValue[2] } : null}
                borderClassName={currentValue ? "green-border" : "grey-border"}
              />
            </div>
          </div>
        </div>
      );

    case 'list':
      return (
        <>
          <label htmlFor={param.name}>{param.label}</label>
          {param.args.map((arg, index) => (
            <ListInputItem
              key={index}
              index={index}
              handleInputChange={handleInputChange}
              clauseId={clauseId}
              subClauseId={subClauseId}
              control={control}
              fieldName={fieldName}
              param={param}
              isEditing={isEditing}
              isScrolling={false}
            />
          ))}
        </>
      );

    case 'enum':
      const enumOptions = param.args.map((arg) => arg.option);
      return (
        <>
          <div className="form-group">
            <div
              className={`d-flex flex-column  ${!isValidInput ? "grey-border" : "green-border"
                }`}
            >
              <div className="d-flex align-items-start justify-content-between py-2 px-2">

                <label className="ms-2">{param.label}</label>
                {progressElement}
              </div>
              <div className="radio-group flex-column align-items-start px-3 pb-1 gap-0">
                {enumOptions.map((option, index) => (
                  <div key={`${fieldName}_${index}`} className="form-check">
                    <div>
                      <input
                        type="radio"
                        className="form-check-input"
                        value={index}
                        checked={currentValue === index}
                        onChange={() => {
                          handleInputChange(
                            { target: { value: index } },
                            param.name,
                            clauseId,
                            subClauseId
                          );
                        }}
                      />
                      <label className="form-check-label" htmlFor={String(index)}>
                        {option}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    case 'number':
      return (
        <div>
          <div
            className={`d-flex gap-2 align-items-center ${currentValue ? "green-border" : "grey-border"
              }`}
            style={{
              boxShadow: isFocused ? "0 0 0 2px #893aee" : "none",
              // padding: "5px",
            }}
          >
            {progressElement}
            <input
              disabled={(param as any).formula ? true : false}
              value={currentValue || formulaValue}
              {...register(fieldName, { required: true })}
              onChange={(e) => {
                e.target.value = validNumberInput(e.target.value) ? e.target.value
                  : validNumberInput(currentValue) ? currentValue
                    : ''
                handleInputChange(e, param.name, clauseId, subClauseId)
              }
              }
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              className="custom-textarea"
              placeholder={param.label}

            />
          </div>
          {
            constraintResults && constraintResults.filter(c => !c[1]).length > 0 && <>
              {constraintResults.map((result, idx) => {
                return !result[1] ? <div key={idx} className="error-message-dialog">{result[0]}</div> : ""
              })}</>
          }
        </div>

      );
      case 'static-table':
        return null
      case 'index':
        return null
    default:
      return (
        <div>
          <div
            className={`d-flex gap-2 align-items-center ${currentValue ? "green-border" : "grey-border"
              }`}
            style={{
              boxShadow: isFocused ? "0 0 0 2px #893aee" : "none",
              // padding: "5px",
            }}
          >
            {progressElement}
            <textarea
              disabled={(param as any).formula ? true : false}
              value={currentValue || formulaValue}
              {...register(fieldName, { required: true })}
              onChange={(e) =>
                handleInputChange(e, param.name, clauseId, subClauseId)
              }
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              className="custom-textarea"
              placeholder={param.label}
            />
          </div>
          {
            constraintResults && constraintResults.filter(c => !c[1]).length > 0 && <>
              {constraintResults.map((result, idx) => {
                return !result[1] ? <div key={idx} className="error-message-dialog">{result[0]}</div> : ""
              })}</>
          }

        </div>
      );
  }
}
export default ParameterInput;
