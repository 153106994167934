/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useMemo } from 'react'
import useApiClientWithLoading from "../../services/api/ApiClient";
import { useParams } from 'react-router-dom';
import { FormTemplateClient } from '../../services/api/FormTemplateClient';
import { useTranslation } from '../../contexts/TranslationProvider';
import { ContractTemplateEntity, FormTemplateEntity, FormTemplatePageEntity, GroupEntity } from '../../domain/entities';
import EditFormTemplateContext, { EditFormTemplateContextType } from '../../contexts/EditFormTemplateContext';
import Loading from "../../components/common/Loading";
import EditFormTemplate from './components/EditFormTemplate';
import { FormParam } from '../../domain/types/FormParams';
import { EnumOption, ListOption, TableLigne } from '../../domain/types/ClauseParams';
import { GroupClient } from '../../services/api/GroupClient';
import EventManager from '../../services/EventManager';
import { getAllParams } from '../../domain/FormTemplate';

function FormTemplatePage() {
    const [loading, setLoading] = useState(false);
    const [groups, setGroups] = useState<GroupEntity[]>([]);
    const apiClient = useApiClientWithLoading();
    const formTemplateClient = new FormTemplateClient(apiClient);
    const groupClient = new GroupClient(apiClient);
    const { formTemplateId } = useParams();
    const { setLanguage, language, t } = useTranslation();
    const [formTemplate, _setFormTemplate] = useState<FormTemplateEntity | null>(null);
    const [paramValues, setParamValues] = useState<Record<string, any>[] | null>([]);

    const idfyFormTemplate: EditFormTemplateContextType['idfyFormTemplate'] = (formTemplate) => {
        formTemplate.pages?.forEach((page, PageIndex) => {
            page.params.forEach((param, paramIndex) => {
                param.id = `${PageIndex}-${paramIndex}-${param.name}`
            })
        })
        return formTemplate
    }

    const setFormTemplate: EditFormTemplateContextType['setFormTemplate'] = (value) => {
        if (typeof value == 'function') {
            _setFormTemplate((prev) => {
                return idfyFormTemplate(value(prev))
            })
        } else {
            _setFormTemplate(idfyFormTemplate(value))
        }
    }

    const setTemplateGroups: EditFormTemplateContextType['setTemplateGroups'] = (group: GroupEntity, add: boolean) => {
        let newGroups = [...formTemplate.groups];
        if (add) {
            newGroups = [...formTemplate.groups, {
                ...group,
                Group_FormTemplate: {
                    pageCodes: formTemplate.pages.map(clause => clause.index),
                    viewPageCodes: []
                }
            }];
        } else {
            newGroups = newGroups.filter(g => g.id !== group.id);
        }
        const newFormTemplate = { ...formTemplate };
        newFormTemplate.groups = newGroups;
        setFormTemplate(newFormTemplate);
    }
    const onSelectGroupInFormPage: EditFormTemplateContextType['onSelectGroupInFormPage'] = async (index: string, groups: FormTemplateEntity['groups'], viewGroups?: FormTemplateEntity['groups']) => {
        const templateGroups = formTemplate.groups;
        templateGroups.forEach(group => {
            if (groups.some(g => g.id === group.id)) {
                if (!group.Group_FormTemplate.pageCodes.includes(index)) {
                    group.Group_FormTemplate.pageCodes.push(index);
                }
            } else {
                group.Group_FormTemplate.pageCodes = group.Group_FormTemplate.pageCodes.filter(code => code !== index);
            }
            if (viewGroups.some(g => g.id === group.id)) {
                if (!group.Group_FormTemplate.pageCodes.includes(index)) {
                    group.Group_FormTemplate.viewPageCodes.push(index);
                }
            } else {
                group.Group_FormTemplate.viewPageCodes = group.Group_FormTemplate.viewPageCodes.filter(code => code !== index);
            }
        })
        const updatedFormTemplate = { ...formTemplate };
        updatedFormTemplate.groups = templateGroups;
        setFormTemplate(updatedFormTemplate);
    };

    const applyOrder = (oldOrder: FormParam[], orderedItems: { name: string, index: number }[]) => {
        let nonOrderedItems = oldOrder.filter(param => !orderedItems.find(item => item.name === param.name));
        let result = orderedItems.map(item => oldOrder.find(param => param.name === item.name)).filter(p => p !== undefined);
        result = [...result, ...nonOrderedItems];
        return result;
    }

    const addPage: EditFormTemplateContextType['addPage'] = async (index: string, name: string) => {
        const updateBeforeAdd = await formTemplateClient.update(formTemplate.id, formTemplate)
        console.log(index, name, formTemplateId)
        const newTemplate = await formTemplateClient.addPage(parseInt(formTemplateId), name, index.toString());
        setFormTemplate(newTemplate);
    }
    const onPageParamNameChanged = (page: FormTemplatePageEntity, prevName: FormParam['name'], newName: FormParam['name']) => {
        page.params = page.params.map(p => {
            if (p.name == prevName) {
                return {
                    ...p,
                    name: newName,
                }
            }
            return p
        })
    }
    const onParamNameChanged: EditFormTemplateContextType['onParamNameChanged'] = (prevName: FormParam['name'], newName: FormParam['name']) => {
        let newFormTemplateValue = { ...formTemplate };
        newFormTemplateValue.pages.forEach(page => {
            onPageParamNameChanged(page, prevName, newName)
        });
        setFormTemplate(newFormTemplateValue)
    };

    const onPageParamChanged = (page: FormTemplatePageEntity, paramName: FormParam['name'], updatedParam: FormParam) => {
        page.params = page.params.map(p => {
            if (p.name == paramName) {
                return {
                    ...updatedParam,
                    label: p.label,
                    condition: p.condition,
                }
            }
            return p
        })
    }
    const onParamChanged: EditFormTemplateContextType['onParamChanged'] = (paramName: FormParam['name'], updatedParam: FormParam) => {
        let newFormTemplateValue = { ...formTemplate };
        newFormTemplateValue.pages.forEach(page => {
            onPageParamChanged(page, paramName, updatedParam)
        });
        setFormTemplate(newFormTemplateValue)
    };

    const onPageParamTypeChange = (page: FormTemplatePageEntity, paramName: FormParam['name'], type: FormParam['type']) => {
        page.params = page.params.map(param => {
            if (param.name == paramName) {
                switch (type) {
                    case "boolean":
                        return {
                            name: param.name,
                            label: param.label,
                            condition: param.condition,
                            type: 'boolean',
                        }
                    case "enum":
                        return {
                            name: param.name,
                            label: param.label,
                            condition: param.condition,
                            type: 'enum',
                            args: []
                        }
                    case "beneficial":
                        return {
                            name: param.name,
                            label: param.label,
                            condition: param.condition,
                            type: 'beneficial',
                            args: {
                                beneficialTypes: []
                            }
                        }
                    case "beneficial[]":
                        return {
                            name: param.name,
                            label: param.label,
                            condition: param.condition,
                            type: 'beneficial[]',
                            args: {
                                beneficialTypes: ['Company', 'Minor', 'Person'],
                            }
                        } as FormParam
                    case "list":
                    case "table":
                        return {
                            name: param.name,
                            label: param.label,
                            condition: param.condition,
                            type,
                            args: []
                        } as FormParam
                    case "property":
                        return {
                            name: param.name,
                            label: param.label,
                            condition: param.condition,
                            type,
                            args: { tag: "" }
                        } as FormParam
                    case "number":
                    case "date":
                    case "comment":
                    case "csv":
                    case "file":
                    case "string":
                        return {
                            name: param.name,
                            label: param.label,
                            condition: param.condition,
                            type,
                        }
                    case "static-table":
                        return {
                            name: param.name,
                            label: param.label,
                            condition: param.condition,
                            type,
                        } as FormParam
                    default:
                        return param
                }
            }
            return param
        })
    }

    const onParamTypeChange: EditFormTemplateContextType['onParamTypeChange'] = (paramName: FormParam['name'], type: FormParam['type']) => {
        let newFormTemplateValue = { ...formTemplate };
        newFormTemplateValue.pages.forEach(page => {
            onPageParamTypeChange(page, paramName, type)
        });
        setFormTemplate(newFormTemplateValue)
    };


    const onParamLabelChange: EditFormTemplateContextType['onParamLabelChange'] = (formPageId: FormTemplatePageEntity['id'], paramId: FormParam['id'], label: FormParam['label']) => {
        let newFormTemplateValue = { ...formTemplate };
        let page = newFormTemplateValue.pages.find(p => p.id == formPageId)

        if (page) {
            page.params = page.params.map(p => p.id == paramId ? { ...p, label } : p)
        }

        setFormTemplate(newFormTemplateValue)
    }
    const onParamConditionChange: EditFormTemplateContextType['onParamConditionChange'] = (formPageId: FormTemplatePageEntity['id'], paramId: FormParam['id'], condition: FormParam['condition']) => {
        let newFormTemplateValue = { ...formTemplate };
        let page = newFormTemplateValue.pages.find(p => p.id == formPageId)

        if (page) {
            page.params = page.params.map(p => p.id == paramId ? { ...p, condition } : p)
        }

        setFormTemplate(newFormTemplateValue)
    }

    const onValueChange: EditFormTemplateContextType['onValueChange'] = (formPageId: FormTemplatePageEntity['id'], param: FormParam, value: any) => {
        let newFormTemplateValue = { ...formTemplate };
        let pageToUpdate = newFormTemplateValue.pages.find(page => page.id === formPageId);

        if (pageToUpdate) {
            let paramToUpdate = pageToUpdate.params.find(p => p.name === param.name);
            if (paramToUpdate) {
                setParamValues(prev => ({ ...prev, [param.name]: value }));
            }
        }
    }

    const onResetValue: EditFormTemplateContextType['onResetValue'] = (formPageId: FormTemplatePageEntity['id'], param: FormParam) => {
        let newFormTemplateValue = { ...formTemplate };
        let pageToUpdate = newFormTemplateValue.pages.find(page => page.id === formPageId);

        if (pageToUpdate) {
            let paramToUpdate = pageToUpdate.params.find(p => p.name === param.name);
            if (paramToUpdate) {
                setParamValues(prev => {
                    delete prev[param.name];
                    return prev;
                });
            }
        }
    }

    const onAddParam: EditFormTemplateContextType['onAddParam'] = async (formPageId: FormTemplatePageEntity['id'], param: FormParam) => {
        let newFormTemplateValue = { ...formTemplate };
        let pageToUpdate = newFormTemplateValue.pages.find(page => page.id === formPageId);
        console.log('param passed to fct:>> ', param);
        const existingParam = getAllParams(formTemplate).find(p => p.name == param.name)
        if (existingParam) {
            param = {
                ...param,
                args: (existingParam as any).args,
            } as any
        }
        if (param.type === 'beneficial') {
            param.args = {
                beneficialTypes: ['Person']
            }
        }
        if (param.type === 'property') {
            param.args = {
                tag: "",
                parentParam: "",
            }
        }
        if (param.type == 'list' || param.type == 'enum' || param.type == 'table') {
            param.args = []
        }
        if (pageToUpdate) {
            // add param to the start if it is not nested
            if (!param.condition || param.condition.length === 0) {
                pageToUpdate.params = [param, ...pageToUpdate.params];
            } else {
                pageToUpdate.params = [...pageToUpdate.params, param];
            }
            setFormTemplate(newFormTemplateValue);
            console.log('formTemplate  after adding new param:>> ', formTemplate);
        } else {
            console.error(`Page with id ${formPageId} not found.`);
        }
    }
    const onSetNewOrder: EditFormTemplateContextType['onSetNewOrder'] = async (formPageId: FormTemplatePageEntity['id'], newOrder: { name: string, index: number }[]) => {
        let newFormTemplateValue = { ...formTemplate };
        let pageToUpdate = newFormTemplateValue.pages.find(page => page.id === formPageId);
        const oldOrder = pageToUpdate?.params || [];
        const newParams = applyOrder(oldOrder, newOrder);
        pageToUpdate.params = newParams;
        setFormTemplate(newFormTemplateValue);
    }

    const onDeleteParam: EditFormTemplateContextType['onDeleteParam'] = async (formPageId: FormTemplatePageEntity['id'], paramId: FormParam['id']) => {
        let newFormTemplateValue = { ...formTemplate };
        let pageToUpdate = newFormTemplateValue.pages.find(page => page.id === formPageId);

        if (pageToUpdate) {
            pageToUpdate.params = pageToUpdate.params.filter((p, index) =>
                p.id !== paramId
            );
            setFormTemplate(newFormTemplateValue);
        } else {
            console.error(`Page with id ${formPageId} not found.`);
        }
    }

    const onPageRename: EditFormTemplateContextType['onPageRename'] = async (formPageId: FormTemplatePageEntity['id'], newName: string) => {
        let newFormTemplateValue = { ...formTemplate };
        let pageToUpdate = newFormTemplateValue.pages.find(page => page.id === formPageId);

        if (pageToUpdate) {
            pageToUpdate.name = newName;
            setFormTemplate(newFormTemplateValue);
        } else {
            console.error(`Page with id ${formPageId} not found.`);
        }
    }

    const onTemplateRename: EditFormTemplateContextType['onTemplateRename'] = async (newName: string) => {
        let newFormTemplateValue = { ...formTemplate };
        newFormTemplateValue.name = newName;
        setFormTemplate(newFormTemplateValue);
    }

    const onPageDelete: EditFormTemplateContextType['onPageDelete'] = async (index: FormTemplatePageEntity['index']) => {
        const updateBeforDelete = await formTemplateClient.update(formTemplate.id, formTemplate)
        let newFormTemplateValue = await formTemplateClient.removePage(formTemplate.id, index)

        setFormTemplate(newFormTemplateValue);
    }


    useEffect(() => {
        (async () => {
            const row = await formTemplateClient.getById(parseInt(formTemplateId));
            const groupData = await groupClient.getAll();
            setGroups(groupData.rows);
            if (!row) return;
            if (row.languages) {
                const { languages } = row;
                if (languages.length !== 0 && (!(languages as string[]).includes(language))) {
                    setLanguage(languages[0]);
                }
            }
            setFormTemplate(row);
        })();
    }, []);

    const EditFormTemplateContextValue: EditFormTemplateContextType = useMemo(() => ({
        formTemplate,
        setFormTemplate,
        idfyFormTemplate,
        addPage,
        onParamNameChanged,
        onParamChanged,
        onParamLabelChange,
        onParamConditionChange,
        onDeleteParam,
        onAddParam,
        onValueChange,
        paramValues,
        setParamValues,
        onResetValue,
        onSetNewOrder,
        onPageRename,
        onTemplateRename,
        onPageDelete,
        onSelectGroupInFormPage,
        onParamTypeChange,
        groups,
        setTemplateGroups
    }), [formTemplate,
        setFormTemplate,
        idfyFormTemplate,
        addPage,
        onParamNameChanged,
        onParamChanged,
        onParamTypeChange,
        onDeleteParam,
        onAddParam,
        onValueChange,
        paramValues,
        setParamValues,
        onResetValue,
        onSetNewOrder,
        onPageRename,
        onTemplateRename,
        onPageDelete,
        onSelectGroupInFormPage,
        groups,
        setTemplateGroups
    ]);

    useEffect(() => {
        EventManager.invoke("EditFormTemplateContext", EditFormTemplateContextValue)
        return () => {
        }
    }, [EditFormTemplateContextValue])
    return (
        <EditFormTemplateContext.Provider value={EditFormTemplateContextValue}>
            <div className="form-container form-page">
                <div className="d-flex flex-column">
                    {loading ? (<Loading height="90vh" />) : (
                        <EditFormTemplate />
                    )}
                </div>
            </div>
        </EditFormTemplateContext.Provider>
    )
}

export default FormTemplatePage