import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { fetchTextFileUrl, getCompletionClassName } from '../../helpers/helper';
// import { documentUseCaseResponse, getDocumentById } from '../../services/api/DocumentApi';
import useApiClientWithLoading from "../../services/api/ApiClient";
import { LoadingContext } from "../../contexts/LoadingContext";
import Loading from '../../components/common/Loading';
import './responseConfig.scss'
import QuestionCard from './components/QuestionCard';
import RiskRenderer from './components/RiskRenderer';
import { DocumentUseCaseResponseClient } from '../../services/api/DocumentUseCaseResponseClient';
import AiValidationContext from '../../contexts/AiValidationContext';
import EventManager from '../../services/EventManager';
import { toast } from 'react-toastify';
import { useTranslation } from '../../contexts/TranslationProvider';
import JustificationProvider from '../../contexts/JustificationContext';
import TextRender from './TextRender';

function ResponseConfiguration() {
  const [text, setText] = useState<string>(null);
  const { isLoading, setLoading } = useContext(LoadingContext);
  const [useCaseResponse, setUseCaseResponse] = useState<any>(null);
  const { usecaseId } = useParams<{ usecaseId?: string }>() || {};
  const { t, language, setLanguage } = useTranslation()
  const {
    setAiCompletionPercentage,
    aiCompletionPercentage
  } = useContext(AiValidationContext);
  //const { nameDocument, questions, answers, responseValidation, useCaseResponsesId, response } = location.state;
  const [ValidationResponsesStatus, setValidationResponsesStatus] = useState([]);

  const apiClient = useApiClientWithLoading(setLoading);
  const documentUseCaseResponseClient = new DocumentUseCaseResponseClient(apiClient)
  const translationPath = t("pages.responseConfig")
  useEffect(() => {
    (async () => {
      const row = await documentUseCaseResponseClient.getById(parseInt(usecaseId));
      if (row.useCase) {
        const { languages } = row.useCase;
        if (languages.length !== 0 && (!(languages as string[]).includes(language))) {
          setLanguage(languages[0]);
        }
      }
      const text = await fetchTextFileUrl(row?.document?.textFile?.url);
      setUseCaseResponse(row);
      setText(text);
      setValidationResponsesStatus(row.responseValidation);
    })();
  }, [usecaseId]);

  const getAiResponseStatus = (item) => {
    const isAcceptedAnswer = item === 1;
    const isRemovedAnswer = item === 2;

    return {
      isAcceptedAnswer,
      isRemovedAnswer,
    };
  };
  const handleAddClick = (index) => {
    setValidationResponsesStatus((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[index] = 1;
      return updatedAnswers;
    });
  };
  const handleRemoveClick = (index) => {
    setValidationResponsesStatus((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[index] = 2;
      return updatedAnswers;
    });
  };

  useEffect(() => {
    const saveValidationResponseHandler = async () => {
      if (useCaseResponse && useCaseResponse.type === "Question") {
        try {
          await documentUseCaseResponseClient.updateUseCaseResponse(useCaseResponse?.id, {
            responseValidation: ValidationResponsesStatus,
          });
          toast.success(t(translationPath.messages.success));

        } catch (error) {
          console.error('Error saving validation response:', error);
          toast.error(t(translationPath.messages.error));

        }
      }
    };
    EventManager.subscribe('SaveValidationResponse', saveValidationResponseHandler);
    return () => {
      EventManager.unsubscribe('SaveValidationResponse', saveValidationResponseHandler);
    };
  }, [ValidationResponsesStatus, useCaseResponse?.id]);
  useEffect(() => {
    if (useCaseResponse && useCaseResponse.type === "Question") {
      const validatedAnswers = ValidationResponsesStatus.filter(status => status === 1);
      const removedAnswers = ValidationResponsesStatus.filter(status => status === 2);

      const newCompletionPercentage = Math.round(
        ((validatedAnswers.length + removedAnswers.length) / useCaseResponse.response.length) * 100
      );

      setAiCompletionPercentage(newCompletionPercentage);
    }
  }, [ValidationResponsesStatus, useCaseResponse]);

  const UseCaseRenderer = ({ useCaseResponse }) => {
    switch (useCaseResponse.type) {
      case "Risk":
        return <RiskRenderer useCaseResponse={useCaseResponse} />;
      default:
        return useCaseResponse.response.map((item, index) => {
          const { isAcceptedAnswer, isRemovedAnswer } = getAiResponseStatus(ValidationResponsesStatus[index]);
          return (
            <QuestionCard
              question={item[0]}
              answer={item[1]}
              isAcceptedAnswer={isAcceptedAnswer}
              isRemovedAnswer={isRemovedAnswer}
              handleAddClick={() => handleAddClick(index)}
              handleRemoveClick={() => handleRemoveClick(index)}
              index={index}
            />
          );
        });
    }
  }
  return (
    <JustificationProvider>
      <div className='use-case-container' >
        {useCaseResponse ? (
          <>
            <div className="ai-text-section">
              <h1 className="section-title px-3">{useCaseResponse.document?.name}</h1>
              <div className='d-flex p-1 gap-5 px-3'>
                <TextRender text={text} />
                <div className="ai-questions-section">
                  {useCaseResponse.type !== "Risk" && <div className="d-flex justify-content-between">
                    <h4>{t(translationPath.edition.title)}</h4>
                    <h5 className="me-3">
                      {t(translationPath.edition.progress)}{" "}
                      <span className={getCompletionClassName(aiCompletionPercentage)}>
                        {aiCompletionPercentage}%
                      </span>{" "}
                    </h5>
                  </div>}
                  <div className="ai-questions-container">
                    {<UseCaseRenderer useCaseResponse={useCaseResponse} />}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Loading height="90vh" />
        )}
      </div>
    </JustificationProvider>
  );
}

export default ResponseConfiguration;
