import React, { useState, useEffect, useMemo } from 'react'
import "./ressource.scss"
import ContractCardActionsModal from '../../../modals/ContractCardActionsModal'
import { useTranslation } from '../../../../contexts/TranslationProvider'
import { FiMoreHorizontal } from "react-icons/fi";
import form from "../../../../assets/new-design-icons/Form.svg"
import contract from "../../../../assets/new-design-icons/Contract.svg"
import calculate from "../../../../assets/new-design-icons/Calculate.svg"
import chat from "../../../../assets/new-design-icons/Chat.svg"
import { ar, fr, enUS } from 'date-fns/locale';
import { formatDistanceToNow } from 'date-fns';
import { ContractEntity, FormEntity, DocumentEntity } from '../../../../domain/entities';
import { getTypeLevels } from '../../../../helpers/helper';
import FormBody from './content/FormBody';
import ContractBody from './content/ContractBody';
import DocumentBody from './content/DocumentBody';
import { LoadingRessourceContext } from '../../../../contexts/LoadingRessourceContext';
import Loading from '../../Loading';
const aiIcon = require('../../../../assets/new-design-icons/ai.png')
const docIcon = require('../../../../assets/new-design-icons/doc.png')

function RessourceCard({
  data,
  onDelete,
  onClick
}) {
  const formIcon = form.toString()
  const contractIcon = contract.toString()
  const [updatedSince, setUpdatedSince] = useState("")
  const [createdSince, setCreatedSince] = useState("")
  const {loadingRessources} = React.useContext(LoadingRessourceContext);
  const isLoading = loadingRessources.some(ressource => ressource.id === data.id && ressource.type === data.type);
  const calculateIcon = calculate.toString()
  const chatIcon = chat.toString()
  const { t, language } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const togglePopup = (e) => {
    e.stopPropagation();
    setIsPopupOpen(!isPopupOpen);
  };


  useEffect(() => {
    const updateDate = new Date(data?.updatedAt);
    const creationDate = new Date(data?.createdAt);
    if (Date.parse(updateDate.toString())) {
      const localeMap = {
        en: enUS,
        fr: fr,
        ar: ar
      };
      const locale = localeMap[language] || enUS;
      const time = formatDistanceToNow(updateDate, { addSuffix: true, locale });
      setUpdatedSince(time);
    }
    if (Date.parse(creationDate.toString())) {
      const localeMap = {
        en: enUS,
        fr: fr,
        ar: ar
      };
      const locale = localeMap[language] || enUS;
      const time = formatDistanceToNow(creationDate, { addSuffix: true, locale });
      setCreatedSince(time);
    }
  }, [data, language]);
  const bodyRender = (data, typeDisplay) => {
    switch (data.type) {
      case ('Form'):
        return (<FormBody type={typeDisplay} data={data} updatedSince={updatedSince} createdSince={createdSince} />)
      case ('Contract'):
      case ('ContractAI'):
      case ('Summary'):
        return (<ContractBody type={typeDisplay} data={data} updatedSince={updatedSince} createdSince={createdSince} />)
      case ('Document'):
        if (data.level1Id == null)  {
          return (<DocumentBody data={data} updatedSince={updatedSince} createdSince={createdSince} />)
        } else {
          return (<ContractBody type={typeDisplay} data={data} updatedSince={updatedSince} createdSince={createdSince} />)
        }

      default:
        return null;
    }
  }
  const handleClick = () => {
    if(isLoading)
      return;
    onClick()
  }
  return (
    <div onClick={handleClick} key={data.id + '-' + data.type} className='ressource-card-container'>
      <div className='header'>
        <div className='title'>
          <div className='icon'>
            {
              data.type === 'Form' ? <img src={formIcon} alt="icon" style={{ width: "30px", height: "30px" }} /> :
                data.type === 'Contract' || data.type === 'ContractAI' ? <img src={contractIcon} alt="icon" style={{ width: "30px", height: "30px" }} /> :
                data.type === 'Document' ? (
                  data.level1Id === null ? (
                    <img src={docIcon} alt="icon" style={{ width: "25px", height: "25px" }} />
                  ) : (
                    <img src={contractIcon} alt="icon" style={{ width: "30px", height: "30px" }} />
                  )
                ) :
                    <></>
            }
          </div>
          <div className='name'>
            {data.name}
          </div>
        </div>
        <div className='actions'>
          <button className="plus-icon" onClick={(e) => togglePopup(e)}>
            <FiMoreHorizontal size={20} />
          </button>
          {
           ( data.type === "ContractAI" || (data.type === "Document" && data.level1Id != null)) &&
            <img src={aiIcon} alt="ai-icon" className="ai-icon" />
          }
          {
            data.type === "Document" &&
            data.useCaseResponses &&
            data.useCaseResponses.length > 0 &&
            <img src={aiIcon} alt="ai-icon" className="ai-icon" />
          }
          {
            data.type === "Form" &&
            (data.form?.isAIGenerated || data.isAIGenerated) &&
            <img src={aiIcon} alt="ai-icon" className="ai-icon" />
          }
          {isPopupOpen &&
            <div className='popup'>
              <ContractCardActionsModal onView={handleClick} onDelete={onDelete} />
            </div>
          }
        </div>
      </div>
      {
        bodyRender(
          data, 
          [data.level1?.name, data.level2?.name, data.level3?.name]
            .filter(Boolean) 
            .join('/')      
        )
      }
      <div className='footer'>
        <div className={`users-display ${language == "ar" && "users-arabic-display"}`}>
          {data.users?.slice(0, 2).map((user, index) => (
            <div key={user.id} className={`avatar-circle layer-${2 - index}`}>
              {user?.email ? <p>{user.email.charAt(0).toUpperCase()}</p> : null}
            </div>
          ))}
          {(data.users?.length > 2) ? (
            <div className="avatar-circle layer-3">
              <p>+{(data.users.length - 2)}</p>
            </div>
          ) : null}
          {data.users && data.users.length > 0 && data.users[0] && data.users[0].fullName &&
            <div className='user-name'>
              {data.users[0].fullName}
            </div>
          }
        </div>
        <div className='status'>
          {(data.type === "Document" && !data.level1Id) 
            ? null 
            : (data.status ? t("status." + data.status) : "")}
          </div> 
        
      </div>
    </div>
  )
}

export default RessourceCard