import { ClauseEntity, ContractEntity, ContractTemplateEntity, SubClauseEntity, TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity } from "./entities";
import { ClauseParam, ClauseParams, RenderSegments, SegmentedClauseParam, SegmentedClauseParams, SegmentedEnumParam, SegmentedText, SegmentedTextType } from "./types/ClauseParams";
import _ from 'lodash'
import { FormParam } from "./types/FormParams";
import { groupByUnique } from "../utils/array";

var counter = 0
export const genIdFactory = (code: string) => {
	counter = 0
	return () => `${code}-${++counter}`
}

export function getInitialParamValues(contractTemplate: ContractTemplateEntity): ContractEntity['paramValues'] {
	const params = getAllParams(contractTemplate)
	let paramValues = {}
	params.forEach(p => {
		switch (p.type) {
			case 'csv':
			case 'table':
				paramValues[p.name] = []
				break;
			case 'list':
				paramValues[p.name] = []
				p.args.forEach((arg, index) => {
					if (arg.required)
						paramValues[p.name].push(index)
				})
				break;
			default:
				break;
		}
	})
	return paramValues
}

export function updateParamNameInText(text: string, oldName: string, newName: string) {
	return text.replace(new RegExp(`\\$${oldName}(\\b|\\.)`, 'g'), `$${newName}$1`);
}
/**
 * search segment by id
 */
export function findSegmentByIdInSegmentation(segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[], id: string,
	onFound?: (segmentedText: SegmentedText, segment: SegmentedText[number]) => void
): SegmentedText[number] {
	for (let segment of segmentedText) {
		const [segmentId] = segment
		if (segmentId === id) {
			onFound?.(segmentedText, segment)
			return segment
		}
	}
	let found: SegmentedText[number] = null
	for (let param of segmentedParams) {
		switch (param.type) {
			case 'boolean':
				found = findSegmentByIdInSegmentation(param.args.textIfFalse, [], id, onFound)
				found = found || findSegmentByIdInSegmentation(param.args.textIfTrue, [], id, onFound)
				break;

			case 'enum':
				param.args.forEach(arg => {
					found = found || findSegmentByIdInSegmentation(arg.text, [], id, onFound)
				})
				break;
		}
		if (found) {
			return found
		}
		return found
	}

}
export function findSegmentByIdInClause(contractTemplate: ContractTemplateEntity, clauseId: ClauseEntity['id'], subClauseId: ClauseEntity['id'], id: string,
	onFound?: (segmentedText: SegmentedText, segment: SegmentedText[number]) => void
): SegmentedText[number] {
	let clause: ClauseEntity
	let subClause: SubClauseEntity
	let clauseIdx: number
	let subClauseIdx: number
	let segmentation: ClauseEntity['segmentation']
	clauseIdx = contractTemplate.clauses!.findIndex(c => c.id == clauseId)
	clause = contractTemplate.clauses[clauseIdx]

	if (subClauseId) {
		subClauseIdx = clause.subClauses!.findIndex(sc => sc.id == subClauseId)
		subClause = clause.subClauses[subClauseIdx]
		segmentation = subClause.segmentation
	} else {
		segmentation = clause.segmentation
	}
	const found = findSegmentByIdInSegmentation(segmentation.segmentedText, segmentation.segmentedParams, id, onFound)
	if (!found)
		return null
	segmentation = idfySegmentation(segmentation, genIdFactory(subClause?.index || clause.index))

	if (subClauseId) {
		segmentation = idfySegmentation(segmentation, genIdFactory(subClause.index))
		subClause.segmentation = segmentation
		clause.subClauses = [...clause.subClauses]
	} else {
		segmentation = idfySegmentation(segmentation, genIdFactory(clause.index))
		clause.segmentation = segmentation
	}
	return found
}

export function findSegmentByIdInTemplate(contractTemplate: ContractTemplateEntity, id: string,
	onFound?: (segmentedText: SegmentedText, segment: SegmentedText[number]) => void
): SegmentedText[number] {
	let found: SegmentedText[number] = null
	for (const clause of contractTemplate.clauses) {
		found = found || findSegmentByIdInClause(contractTemplate, clause.id, null, id, onFound)
		for (const subClause of clause.subClauses) {
			found = found || findSegmentByIdInClause(contractTemplate, clause.id, subClause.id, id, onFound)
		}
	}
	return found
}

export function getAllParams(template: ContractTemplateEntity, unique = false) {
	let names = {}
	const params: SegmentedClauseParams = []
	template?.clauses?.forEach((clause) => {
		params.push(...(clause.segmentation.segmentedParams ?? []))
		clause.subClauses!.forEach(subClause => {
			params.push(...(subClause.segmentation.segmentedParams ?? []))
		})
	})
	if (unique) {
		const uniqueParams = params.filter((p) => {
			if (names[p.name])
				return false
			names[p.name] = true
			return true
		})
		return uniqueParams
	}
	return params
}

export function SegementedParamToFormParam(param: SegmentedClauseParam): FormParam {
	switch (param.type) {
		case 'beneficial':
		case 'beneficial[]':
		case 'boolean':
		case 'comment':
		case 'csv':
		case 'date':
		case 'enum':
		case 'file':
		case 'list':
		case 'number':
		case 'property':
		case 'string':
		case 'table':
			return param
		case 'index':
		case 'static-table':
			return null
		default:
			break;
	}
}

export function SegementedParamsToFormParams(params: SegmentedClauseParam[]): FormParam[] {
	return params.map(SegementedParamToFormParam).filter(Boolean)
}

export function getAllOriginalParams(template: ContractTemplateEntity) {
	const params: ClauseParams = []
	template?.clauses?.forEach((clause) => {
		params.push(...(clause.params ?? []))
		clause.subClauses!.forEach(subClause => {
			params.push(...(subClause.params ?? []))
		})
	}
	)
	return params
}
export function addPrefixToSegmentedTextId(segmentedText: SegmentedText, prefix: string): SegmentedText {
	return segmentedText.map(([id, text, type]) => {
		return [`${prefix}-${id}`, text, type]
	})
}
export function fixTemplateIndexation(contractTemplate: ContractTemplateEntity): ContractTemplateEntity {
	/*
	clauseCodes should store the index of the clause instead if code
	*/
	contractTemplate.groups?.forEach(group => {
		group.Group_ContractTemplate.clauseCodes = group.Group_ContractTemplate.clauseCodes.map(cc => (contractTemplate.clauses.find(cl => cl.index == cc)?.code))
		group.Group_ContractTemplate.viewClauseCodes = group.Group_ContractTemplate.viewClauseCodes.map(cc => (contractTemplate.clauses.find(cl => cl.index == cc)?.code))
	})
	contractTemplate.clauses.forEach((clause, index) => {
		clause.index = (index + 1).toString()
		clause.segmentation = idfySegmentation(clause.segmentation, genIdFactory(clause.index))
		return clause
	})
	contractTemplate.groups?.forEach(group => {
		group.Group_ContractTemplate.clauseCodes = group.Group_ContractTemplate.clauseCodes.map(cc => (contractTemplate.clauses.find(cl => cl.code == cc)?.index))
		group.Group_ContractTemplate.viewClauseCodes = group.Group_ContractTemplate.viewClauseCodes.map(cc => (contractTemplate.clauses.find(cl => cl.code == cc)?.index))
	})
	return { ...contractTemplate }
}

/**
 * CompileSegmentedText
 */
export function compileSegmentation(segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[]): string {
	return segmentedText.map(([id, value, type], index) => {
		const nextSegment = segmentedText[index + 1]
		switch (type) {
			case SegmentedTextType.PARAM:
				const [paramName] = value.split('.')
				const param = segmentedParams.find(param => param.name === paramName)
				// if the param is not found then return the value as is
				if (!param) {
					return value
				}
				// in case the following segment is a text and not starting with space
				if ((nextSegment && nextSegment[2] === SegmentedTextType.PARAM) || (nextSegment && nextSegment[2] === SegmentedTextType.STATIC && !nextSegment[1].startsWith(" "))) {
					return `\$${value} `
				}
				return `\$${value}`;
			case SegmentedTextType.COMMENT:
				if (nextSegment && nextSegment[2] === SegmentedTextType.STATIC && !nextSegment[1].startsWith(" ")) {
					return `#{${value}} `
				}
				return `#{${value}}`;
			case SegmentedTextType.STATIC:
				return value;
			default:
				throw new Error(`Unsupported SegmentedTextType: ${type}`);
		}
	}).join('');
}

export function removeNotFoundParams(segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[]): { segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[] } {
	if (segmentedParams.length) {
		segmentedParams = segmentedParams.filter(param => {
			switch (param.type) {
				case 'boolean':
					return removeNotFoundParams(param.args?.textIfFalse, []).segmentedText.length
						&& removeNotFoundParams(param.args?.textIfTrue, []).segmentedText.length
				case 'enum':
					return param.args?.filter(arg => removeNotFoundParams(arg.text, []).segmentedText.length).length
				default:
					return true
			}
		})
	}
	const newSegmentedtext = segmentedText.filter(e => {
		const [segmentId, text, type] = e
		return type !== SegmentedTextType.PARAM || segmentedParams.find(param => param.name === text.slice(1).split('.')[0])
	})
	return { segmentedText: newSegmentedtext, segmentedParams }
}

export function idfySegmentedText(segmentedText: ClauseEntity['segmentation']['segmentedText'], genId: () => string): ClauseEntity['segmentation']['segmentedText'] {
	return segmentedText.map((segment, idx) => {
		return [genId(), segment[1], segment[2], segment[3]]
	})
}
export function styleSegmentation(oldSegmentation: ClauseEntity['segmentation'], styledTextSegmentation: ClauseEntity['segmentation']['segmentedText'], deletedSegments: RenderSegments): ClauseEntity['segmentation'] {
	let segmentedParams: SegmentedClauseParams = [];
	let segmentedText: SegmentedText = [];
	oldSegmentation.segmentedParams.forEach((paramSegment, idx) => {
		switch (paramSegment.type) {
			case 'boolean':
				return segmentedParams.push(
					{
						...paramSegment,
						type: 'boolean',
						args: {
							textIfFalse: styleSegmentation({ segmentedText: paramSegment.args?.textIfFalse, segmentedParams: [] }, styledTextSegmentation, deletedSegments).segmentedText,
							textIfTrue: styleSegmentation({ segmentedText: paramSegment.args?.textIfTrue, segmentedParams: [] }, styledTextSegmentation, deletedSegments).segmentedText
						}
					}
				)
			case 'enum':
				return segmentedParams.push(
					{
						...paramSegment,
						type: 'enum',
						args: paramSegment.args?.map((arg => {
							return {
								option: arg.option,
								text: styleSegmentation({ segmentedText: arg.text, segmentedParams: [] }, styledTextSegmentation, deletedSegments).segmentedText
							}
						})),
					}
				)
			case 'static-table':
				return segmentedParams.push(
					{
						...paramSegment,
						type: 'static-table',
						args: {
							...paramSegment.args,
							cells: paramSegment.args.cells.map(row => row.map(cell => {
								return {
									...cell,
									content: styleSegmentation({ segmentedText: cell.content, segmentedParams: [] }, styledTextSegmentation, deletedSegments).segmentedText,
								}
							})),
						}
					}
				)
			default:
				return segmentedParams.push(paramSegment)
		}
	})
	oldSegmentation.segmentedText.forEach(([segmentId, segmentText, segmentType, segmentStyle], idx) => {

		const styledSegments = styledTextSegmentation
			.map((styledSegment, index) => ({ segment: styledSegment, index })) // Map to an object with segment and index
			.filter(({ segment }) => segment[0] === segmentId); // Filter based on segmentId

		// find the indexes of the firs and last appearences of segmentIs
		if (styledSegments.length > 0 && segmentType === SegmentedTextType.STATIC) {
			styledSegments.forEach((styledSegment) => {
				// check if previous item in styledTextSegmentation is a paragraph start
				if (styledSegment.index > 0 && styledTextSegmentation[styledSegment.index - 1][2] === SegmentedTextType.PARAGRAPH_START) {
					segmentedText.push(["new-paragraph", " ", SegmentedTextType.PARAGRAPH_START, styledTextSegmentation[styledSegment.index - 1][3]])
				}
				// check if previous item in styledTextSegmentation is a list element start
				if (styledSegment.index > 0 && styledTextSegmentation[styledSegment.index - 1][2] === SegmentedTextType.LIST_ITEM_START) {
					segmentedText.push(["new-list-item", " ", SegmentedTextType.LIST_ITEM_START, styledTextSegmentation[styledSegment.index - 1][3]])
				}
				segmentedText.push([segmentId, styledSegment.segment[1], segmentType, styledSegment.segment[3]])
			})
		} else if (segmentType === SegmentedTextType.PARAM && styledSegments.length === 1) {
			segmentedText.push([segmentId, segmentText, segmentType, styledSegments[0].segment[3]])
		} else {
			if (!deletedSegments.find((segment) => segment.id === segmentId)) {
				segmentedText.push([segmentId, segmentText, segmentType, segmentStyle])
			}
		}
	});
	return { segmentedText, segmentedParams }
}
export function idfySegmentation(segmentation: ClauseEntity['segmentation'], genId: () => string): ClauseEntity['segmentation'] {
	segmentation = cleanSegmentation(segmentation.segmentedText, segmentation.segmentedParams)
	if (segmentation.segmentedText.length === 0) {
		segmentation.segmentedText = [['tmp', '_', SegmentedTextType.STATIC]]
	}
	let segmentedText = idfySegmentedText(segmentation.segmentedText, genId)
	let segmentedParams: SegmentedClauseParams = [];
	segmentation.segmentedParams.forEach((paramSegment, idx) => {
		switch (paramSegment.type) {
			case 'boolean':
				return segmentedParams.push(
					{
						...paramSegment,
						type: 'boolean',
						args: {
							textIfFalse: idfySegmentedText(paramSegment.args?.textIfFalse ?? [], genId),
							textIfTrue: idfySegmentedText(paramSegment.args?.textIfTrue ?? [], genId)
						}
					}
				)
			case 'enum':
				return segmentedParams.push(
					{
						...paramSegment,
						type: 'enum',
						args: paramSegment.args?.map((arg => {
							return {
								option: arg.option,
								text: idfySegmentedText(arg.text, genId)
							}
						})),
					}
				)
			case 'static-table':
				return segmentedParams.push(
					{
						...paramSegment,
						type: 'static-table',
						args: {
							...paramSegment.args,
							cells: paramSegment.args.cells.map(row => row.map(cell => {
								return {
									...cell,
									content: idfySegmentedText(cell.content.length > 0 ? cell.content : [["id", "_", SegmentedTextType.STATIC]], genId),
								}
							})),
						}
					}
				)
			default:
				return segmentedParams.push(paramSegment)
		}
	})
	return { segmentedText, segmentedParams }
}


/**
 * search segment by id and change the text
 */
export function updateSegment(segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[], id: string, text: string): { segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[] } {
	const updatedSegmentedText: SegmentedText = segmentedText.map(([segmentId, segmentText, segmentType]) => {
		return segmentId === id ? [segmentId, text, segmentType] : [segmentId, segmentText, segmentType];
	});

	const updatedSegmentedParams = segmentedParams.map(param => {
		switch (param.type) {
			case 'boolean':
				param.args.textIfFalse = updateSegment(param.args.textIfFalse, [], id, text).segmentedText;
				param.args.textIfTrue = updateSegment(param.args.textIfTrue, [], id, text).segmentedText;
				return param;

			case 'enum':
				param.args = param.args.map(arg => {
					return {
						option: arg.option,
						text: updateSegment(arg.text, [], id, text).segmentedText
					};
				})
				return param;
			default:
				return param;
		}
	});

	return { segmentedText: updatedSegmentedText, segmentedParams: updatedSegmentedParams };

}

/**
 * search insert a parameter inside a segment
 */

export const insertParamInSegment = (segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[], id: string, newParam: SegmentedClauseParam, definition: number, textBefore: string, textAfter: string, field: string, segments: ClauseEntity['segmentation']['segmentedText'], deletedSegments: RenderSegments): { segmentedText: any[], segmentedParams: SegmentedClauseParam[] } => {
	const relatedSegments = segmentedText.filter(([segmentId]) => segmentId === id);
	if (segmentedParams.length) {
		// insert the new param in the segmented params text
		segmentedParams = segmentedParams.map(param => {
			switch (param.type) {
				case 'boolean':
					return {
						...param,
						args: {
							textIfFalse: insertParamInSegment(param.args?.textIfFalse, [], id, newParam, definition, textBefore, textAfter, field, segments, deletedSegments).segmentedText,
							textIfTrue: insertParamInSegment(param.args?.textIfTrue, [], id, newParam, definition, textBefore, textAfter, field, segments, deletedSegments).segmentedText,
						},
					}
				case 'enum':
					return {
						...param,
						args: param.args?.map(arg => {
							return {
								option: arg.option,
								text: insertParamInSegment(arg.text, [], id, newParam, definition, textBefore, textAfter, field, segments, deletedSegments).segmentedText
							}
						})
					}

				case 'static-table':
					return {
						...param,
						args: {
							...param.args,
							cells: param.args.cells.map(row => row.map(cell => {
								return {
									...cell,
									content: insertParamInSegment(cell.content, [], id, newParam, definition, textBefore, textAfter, field, segments, deletedSegments).segmentedText,
								}
							})),
						}
					}
				default:
					return param
			}
		})
	}
	// Split the segment at the insertion index
	//const [segmentId, segmentText, segmentType] = segmentedText[segmentToUpdateIndex];

	// manage the changed elements
	//const updatedSegment = [segmentId, textBefore + " ", segmentType];
	let paramSegment: SegmentedText[number]
	switch (newParam.type) {
		case 'enum':
			paramSegment = ["new-id", `${newParam.name}.${definition || 0}`, SegmentedTextType.PARAM];
			const lastEnumOccurence = (segmentedParams as SegmentedEnumParam[]).find((par) => par.name == newParam.name && par.definition == definition - 1)
			segmentedParams.push({
				name: newParam.name,
				label: newParam.label,
				type: newParam.type,
				args: lastEnumOccurence ? lastEnumOccurence.args.map(arg => {
					return {
						option: arg.option,
						text: [['tmp', '_', SegmentedTextType.STATIC]]
					}
				}) : newParam.args.map(arg => {
					return {
						option: arg.option,
						text: [['tmp', '_', SegmentedTextType.STATIC]]
					}
				}),
				definition: definition
			})
			break;
		case 'boolean':
			paramSegment = ["new-id", `${newParam.name}.${definition || 0}`, SegmentedTextType.PARAM];
			segmentedParams.push({
				name: newParam.name,
				label: newParam.label,
				type: newParam.type,
				args: {
					textIfTrue: [['tmp', '_', SegmentedTextType.STATIC]],
					textIfFalse: [['tmp', '_', SegmentedTextType.STATIC]],
				},
				definition: definition
			})
			break;
		case 'static-table':
			paramSegment = ["new-id", newParam.name, SegmentedTextType.PARAM];
			segmentedParams.push({
				name: newParam.name,
				label: newParam.label,
				type: newParam.type,
				definition: definition,
				args: {
					...newParam.args,
					cells: newParam.args.cells.map(row => row.map(cell => {
						return {
							...cell,
							content: [['tmp', '_', SegmentedTextType.STATIC]],
						}
					})),
				}
			})

			break;
		case 'beneficial':
		case 'beneficial[]':
			paramSegment = ["new-id", newParam.name + "." + field, SegmentedTextType.PARAM];
			const oldBeneficial = segmentedParams.find((par) => par.name == newParam.name)
			if (!oldBeneficial)
				segmentedParams.push(newParam)
			break;
		default:
			paramSegment = ["new-id", newParam.name, SegmentedTextType.PARAM];
			const exists = segmentedParams.find((par) => par.name == newParam.name)
			if (!exists)
				segmentedParams.push(newParam)
			break;
	}
	const newSegments: ClauseEntity['segmentation']['segmentedText'] = []
	console.log("segmentedText", segmentedText)
	segmentedText.map(([segmentId, segmentText, segmentType, segmentStyle], idx) => {

		const styledSegments = segments
			.map((styledSegment, index) => ({ segment: styledSegment, index })) // Map to an object with segment and index
			.filter(({ segment }) => segment[0] === segmentId); // Filter based on segmentId
		if (styledSegments.length > 0 && (segmentType === SegmentedTextType.STATIC)) {
			styledSegments.forEach((styledSegment) => {
				// check if previous item in styledTextSegmentation is a paragraph start
				if (styledSegment.index > 0 && segments[styledSegment.index - 1][2] === SegmentedTextType.PARAGRAPH_START) {
					newSegments.push(["new-paragraph", " ", SegmentedTextType.PARAGRAPH_START, segments[styledSegment.index - 1][3]])
				}
				// check if previous item in styledTextSegmentation is a list element start
				if (styledSegment.index > 0 && segments[styledSegment.index - 1][2] === SegmentedTextType.LIST_ITEM_START) {
					newSegments.push(["new-list-item", " ", SegmentedTextType.LIST_ITEM_START, segments[styledSegment.index - 1][3]])
				}
				if (styledSegment.segment[2] === SegmentedTextType.PARAM && segmentId === id) {
					newSegments.push(paramSegment)
				} else {
					newSegments.push([segmentId, styledSegment.segment[1], styledSegment.segment[2], styledSegment.segment[3]])
				}
			})
		} else if (segmentType === SegmentedTextType.PARAM && styledSegments.length === 1) {
			newSegments.push([segmentId, segmentText, segmentType, styledSegments[0].segment[3]])
		} else {
			if (!deletedSegments.find((segment) => segment.id === segmentId)) {
				newSegments.push([segmentId, segmentText, segmentType, segmentStyle])
			}
		}
	})
	return { segmentedText: newSegments, segmentedParams: segmentedParams };

}

export type ValidationWarning = {
	message: string;
	templateCode: string;
	clauseCode: string;
	subClauseCode: string;
	paramName: string;
}
export type GenerateTemplateFromDocumentRequest = {
	file: Blob & { name: string };
	name: string;
	isScanned: boolean;
	level1Id?: TypeLevel1Entity['id'];
	level2Id?: TypeLevel2Entity['id'];
	level3Id?: TypeLevel3Entity['id'];
}

export function deleteParamInSegment(segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[], parameter: SegmentedClauseParam) {
	if (segmentedParams.length) {
		const filterSegParams = (p: SegmentedClauseParam) => parameter.name !== p.name;
		segmentedParams = segmentedParams.filter(filterSegParams);
		segmentedParams = segmentedParams.map(param => {
			switch (param.type) {
				case 'boolean':
					return {
						...param,
						args: {
							textIfFalse: deleteParamInSegment(param.args?.textIfFalse, [], parameter).segmentedText,
							textIfTrue: deleteParamInSegment(param.args?.textIfTrue, [], parameter).segmentedText,
						},
					}
				case 'enum':
					return {
						...param,
						args: param.args?.map(arg => {
							return {
								option: arg.option,
								text: deleteParamInSegment(arg.text, [], parameter).segmentedText
							}
						})
					}
				default:
					return param
			}
		})
	}
	const newSegmentedtext = segmentedText.filter(e => {
		const [id, text, type] = e
		if (parameter.type === "enum" || parameter.type === 'boolean') {
			// remove all the segments that contain the parameter name
			return type !== SegmentedTextType.PARAM || !text.includes(`${parameter.name}.`)
		}
		// default case
		return type !== SegmentedTextType.PARAM || text !== parameter.name
	})
	// handle beneficial case
	const cleanedSegmentedText = newSegmentedtext.map((segment) => {
		const [id, text, type] = segment
		if (type === SegmentedTextType.PARAM && text.includes(`${parameter.name}.`)) {
			const newType = SegmentedTextType.STATIC
			const newText = "(" + text.replace(`${parameter.name}.`, "") + ")"
			segment = [id, newText, newType]
		}
		return segment
	})

	return { segmentedText: cleanedSegmentedText, segmentedParams }
}

export function cleanSegmentation(segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[]): { segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[] } {
	if (segmentedParams.length) {
		segmentedParams = segmentedParams.map(param => {
			switch (param.type) {
				case 'boolean':
					return {
						...param,
						args: {
							textIfFalse: cleanSegmentation(param.args?.textIfFalse.length > 0 ? param.args.textIfFalse : [["", "_", SegmentedTextType.STATIC]], []).segmentedText,
							textIfTrue: cleanSegmentation(param.args?.textIfTrue.length > 0 ? param.args.textIfTrue : [["", "_", SegmentedTextType.STATIC]], []).segmentedText,
						},
					}
				case 'enum':
					return {
						...param,
						args: param.args?.map(arg => {
							return {
								option: arg.option,
								text: cleanSegmentation(arg.text.length > 0 ? arg.text : [["", "_", SegmentedTextType.STATIC]], []).segmentedText
							}
						})
					}
				case 'static-table':
					return {
						...param,
						args: {
							...param.args,
							cells: param.args.cells.map(row => row.map(cell => {
								return {
									...cell,
									content: cell.content.length > 0 ? cleanSegmentation(cell.content, []).segmentedText : [["", "_", SegmentedTextType.STATIC]],
								}
							})),

						}
					}
				default:
					return param
			}
		})
	}
	// remove empty segments
	segmentedText = segmentedText.filter(segment => {
		const [segmentId, text, type] = segment
		return text.length > 0 || type === SegmentedTextType.PARAGRAPH_START
	})
	// if first or last segments are not static add static segments
	if (segmentedText.length) {
		const contentSegmentedText = segmentedText.filter(([id, text, type]) => type !== SegmentedTextType.PARAGRAPH_START)
		const firstSegment = contentSegmentedText[0]
		const lastSegment = contentSegmentedText[contentSegmentedText.length - 1]
		if (firstSegment && firstSegment[2] !== SegmentedTextType.STATIC) {
			segmentedText.unshift(["id-first", " ", SegmentedTextType.STATIC])
		}
		if (lastSegment && lastSegment[2] !== SegmentedTextType.STATIC) {
			segmentedText.push(["id-last", " ", SegmentedTextType.STATIC])
		}
		if (contentSegmentedText.length === 0) {
			segmentedText.push(["id-last", " ", SegmentedTextType.STATIC])
		}
	}
	// if two consecutive segments are both params or both paragraph start then add a static segment between them
	const newSegmentedText = [] as SegmentedText
	for (let i = 0; i < segmentedText.length; i++) {
		const currentSegment = segmentedText[i]
		const nextSegment = segmentedText[i + 1]
		newSegmentedText.push(currentSegment)
		if (currentSegment[2] === SegmentedTextType.PARAM && nextSegment && (nextSegment[2] === SegmentedTextType.PARAM || nextSegment[2] === SegmentedTextType.LIST_ITEM_START)) {
			newSegmentedText.push(["id-between", " ", SegmentedTextType.STATIC])
		}
		if (currentSegment[2] === SegmentedTextType.PARAGRAPH_START && nextSegment && nextSegment[2] === SegmentedTextType.PARAGRAPH_START) {
			newSegmentedText.push(["id-between", " ", SegmentedTextType.STATIC])
		}
	}

	// if a paragraph only contains a param add static segments before and after it
	const cleanedSegmentedText = [] as SegmentedText
	for (let i = 0; i < newSegmentedText.length; i++) {
		const currentSegment = newSegmentedText[i]
		const nextSegment = newSegmentedText[i + 1]
		const staticTables = segmentedParams.filter(param => param.type === 'static-table')
		const previousSegment = newSegmentedText[i - 1]
		if (currentSegment[2] === SegmentedTextType.PARAM) {
			if (previousSegment && previousSegment[2] === SegmentedTextType.PARAGRAPH_START) {
				cleanedSegmentedText.push(["id-before", " ", SegmentedTextType.STATIC])
			}
			cleanedSegmentedText.push(currentSegment)
			const isStaticTable = staticTables.find(param => param.name === currentSegment[1])
			if (!isStaticTable && nextSegment && nextSegment[2] === SegmentedTextType.PARAGRAPH_START) {
				cleanedSegmentedText.push(["id-before", " ", SegmentedTextType.STATIC])
			}
		} else {
			cleanedSegmentedText.push(currentSegment)
		}
	}

	// if two consecutive segments are static and share the same styles then merge them
	const mergedSegmentedText = [] as SegmentedText
	for (let i = 0; i < cleanedSegmentedText.length; i++) {
		const currentSegment = cleanedSegmentedText[i]
		const nextSegment = cleanedSegmentedText[i + 1]
		const [segmentId, text, type, style] = currentSegment
		const [nextSegmentId, nextText, nextType, nextStyle] = nextSegment || []
		if (nextSegment
			&& type === SegmentedTextType.STATIC
			&& nextType === SegmentedTextType.STATIC
			&& _.isEqual(style, nextStyle)
		) {
			mergedSegmentedText.push([segmentId, text + nextText, type, style])
			i++
		} else {
			mergedSegmentedText.push(currentSegment)
		}
	}
	return { segmentedText: mergedSegmentedText, segmentedParams }
}

export const deleteSegment = (segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[], id: string, styledSegments: ClauseEntity['segmentation']['segmentedText'], deletedSegments: RenderSegments): { segmentedText: any[], segmentedParams: SegmentedClauseParam[] } => {
	if (segmentedParams.length) {
		segmentedParams = segmentedParams.map(param => {
			switch (param.type) {
				case 'boolean':
					return {
						...param,
						args: {
							textIfFalse: deleteSegment(param.args?.textIfFalse, [], id, styledSegments, deletedSegments).segmentedText,
							textIfTrue: deleteSegment(param.args?.textIfTrue, [], id, styledSegments, deletedSegments).segmentedText,
						},
					}
				case 'enum':
					return {
						...param,
						args: param.args?.map(arg => {
							return {
								option: arg.option,
								text: deleteSegment(arg.text, [], id, styledSegments, deletedSegments).segmentedText
							}
						})
					}
				case 'static-table':
					return {
						...param,
						args: {
							...param.args,
							cells: param.args.cells.map(row => row.map(cell => {
								return {
									...cell,
									content: deleteSegment(cell.content, [], id, styledSegments, deletedSegments).segmentedText,
								}
							})),

						}
					}
				default:
					return param
			}
		})
	}
	const newSegmentedtext = segmentedText.filter(e => {
		const [segmentId, text, type] = e
		return segmentId !== id
	})
	const cleanedSegmentedText = [] as SegmentedText
	newSegmentedtext.forEach(([segmentId, segmentText, segmentType, segmentStyle], idx) => {
		const styledTextSegmentation = styledSegments
			.map((styledSegment, index) => ({ segment: styledSegment, index })) // Map to an object with segment and index
			.filter(({ segment }) => segment[0] === segmentId); // Filter based on segmentId

		if (styledTextSegmentation.length > 0 && segmentType === SegmentedTextType.STATIC) {
			styledTextSegmentation.forEach((styledSegment) => {
				// check if previous item in styledTextSegmentation is a paragraph start
				if (styledSegment.index > 0 && styledSegments[styledSegment.index - 1][2] === SegmentedTextType.PARAGRAPH_START) {
					cleanedSegmentedText.push(["new-paragraph", " ", SegmentedTextType.PARAGRAPH_START, styledSegments[styledSegment.index - 1][3]])
				}
				cleanedSegmentedText.push([segmentId, styledSegment.segment[1], segmentType, styledSegment.segment[3]])
			})
		} else if (segmentType === SegmentedTextType.PARAM && styledSegments.length === 1) {
			cleanedSegmentedText.push([segmentId, segmentText, segmentType, styledTextSegmentation[0].segment[3]])
		} else {
			if (!deletedSegments.find((segment) => segment.id === segmentId)) {
				cleanedSegmentedText.push([segmentId, segmentText, segmentType, segmentStyle])
			}
		}
	})
	//** */
	return { segmentedText: newSegmentedtext, segmentedParams }
}

/**
 * 	oldSegmentation.segmentedText.forEach(([segmentId, segmentText, segmentType, segmentStyle], idx) => {
		if(segmentType === SegmentedTextType.PARAGRAPH_START)
			return

		const styledSegments = styledTextSegmentation
			.map((styledSegment, index) => ({ segment: styledSegment, index })) // Map to an object with segment and index
			.filter(({ segment }) => segment[0] === segmentId); // Filter based on segmentId

		if (styledSegments.length > 0 && segmentType === SegmentedTextType.STATIC) {
			styledSegments.forEach((styledSegment) => {
				// check if previous item in styledTextSegmentation is a paragraph start
				if (styledSegment.index > 0 && styledTextSegmentation[styledSegment.index - 1][2] === SegmentedTextType.PARAGRAPH_START) {
					segmentedText.push(["new-paragraph", " ", SegmentedTextType.PARAGRAPH_START, styledTextSegmentation[styledSegment.index - 1][3]])
				}
				segmentedText.push([segmentId, styledSegment.segment[1], segmentType, styledSegment.segment[3]])
			})
		} else {
			if (!deletedSegments.find((segment) => segment.id === segmentId)) {
				segmentedText.push([segmentId, segmentText, segmentType, segmentStyle])
			}
		}
	});
 */