import { AxiosInstance } from "axios";
import { GroupEntity, Group_UserEntity, UserEntity } from "../../domain/entities";
import { ModelClient } from "../ModelClient";

export class GroupClient extends ModelClient<GroupEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'Group')
  }

  async getUsers(groupId: GroupEntity['id']) {
    const response = await this.apiClient.get<{ rows: UserEntity[] }>(this.modelUrl + `/getUsers?id=${groupId}`);
    return response.data;
  }
  async setGroupUsers(groupUsers: Group_UserEntity[]) {
    return await this.apiClient.put(this.modelUrl + "/setGroupUsers", { data: groupUsers });
  }
  async import(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    const response = await this.apiClient.post<{ rows: Group_UserEntity[], errors: any[] }>(`${this.modelUrl}/importFromXlsx`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  }
  async export() {
    const url = new URL(this.modelUrl + "/exportToXlsx")
    const response = await this.apiClient.get<{ url: string }>(url.href);
    return response.data;
  }
  async upsertGroupUsers(groupUsers: Group_UserEntity[]) {

    return await this.apiClient.put(`${this.modelUrl}/setGroupUsers`, { data: groupUsers });
  }

  async createWithTranslation(group: GroupEntity) {
    return await this.apiClient.post(this.modelUrl + "/createWithTranslation", { data: group });
  }
}