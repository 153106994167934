import "./ressources.scss";
import { BsSearch } from "react-icons/bs";
import { useCallback, useEffect, useMemo, useState } from "react";
import useApiClientWithLoading from "../../services/api/ApiClient";
import Loading from "../../components/common/Loading";
import RessourcesList from "./RessourcesList";
import RessourceTable from "./RessourceTable";
import { useTranslation } from "../../contexts/TranslationProvider";
import CustomCombobox from "../../components/common/CustomCombobox";
import LayoutFilter from "../../components/LayoutFilter";
import { RessourceClient } from "../../services/api/RessourceClient";
import Pagination from "../../components/common/Pagination";
import form from "../../assets/new-design-icons/Form.svg";
import contract from "../../assets/new-design-icons/Contract.svg";
import { RessourceEntity } from "../../domain/entities/Ressource";
import { debounce } from "lodash";
const docIcon = require("../../assets/new-design-icons/doc.png");

function MyRessources() {
  const [sort, setSort] = useState<{
    key: string;
    direction: "asc" | "desc";
  } | null>(null);
  const formIcon = form.toString();
  const contractIcon = contract.toString();
  const [loading, setLoading] = useState(true);
  const [ressources, setRessources] = useState<RessourceEntity[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize,setPageSize] = useState(12);
  const [typeFilter, setTypeFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);
  const [displayCards, setDisplayCards] = useState("grid");

  const apiClient = useApiClientWithLoading();
  const ressourceClient = new RessourceClient(apiClient);
  const { t } = useTranslation();

  const translationPath = "pages.ressources.";
  const types = [
    { name: t(translationPath + "types.contract"), id: "Contract" },
    { name: t(translationPath + "types.document"), id: "Document" },
    { name: t(translationPath + "types.form"), id: "Form" },
  ];
  const statusList = [
    { name: t("status.Draft"), id: "Draft" },
    { name: t("status.Done"), id: "Done" },
    { name: t("status.Negotiation"), id: "Negotiation" },
    { name: t("status.ToBeSigned"), id: "ToBeSigned" },
    { name: t("status.Signed"), id: "Signed" },
  ];

  const fetchRessources = useCallback(async () => {
    setLoading(true);
    try {
      // Check if the searchTerm looks like a date (DD/MM/YYYY format)
      const isDateSearch = /^\d{2}\/\d{2}\/\d{4}$/.test(searchTerm); // Match format like 03/12/2024
  
      // Convert date to formatted date range if it's a date search
      let dateFilter = {};
      if (isDateSearch) {
        const formattedDate = `${searchTerm.split('/')[2]}-${searchTerm.split('/')[1]}-${searchTerm.split('/')[0]}`;
    
        const startOfDay = new Date(`${formattedDate}T00:00:00.000Z`);
        const endOfDay = new Date(`${formattedDate}T23:59:59.999Z`);
        dateFilter = {
          "#or": [
           { name: { "#like": `%${searchTerm}%` } },
           { createdAt: { "#gte": startOfDay.toISOString(), "#lt": endOfDay.toISOString() }},
           { updatedAt: { "#gte": startOfDay.toISOString(), "#lt": endOfDay.toISOString() }},
          ]
        };
      }

      let filter: any = {
        ...(statusFilter && { status: statusFilter.id }),
        ...(searchTerm && !isDateSearch && {
          "#or": [
            { name: { "#like": `%${searchTerm}%` } },
            { "level1.name": { "#like": `%${searchTerm}%` } },
            { "level2.name": { "#like": `%${searchTerm}%` } },
            { "level3.name": { "#like": `%${searchTerm}%` } },
          ],
        }),
        ...(isDateSearch && dateFilter),
      };

      // Apply the type filter based on conditions
      if (typeFilter) {
        if (typeFilter.id === "Contract") {
          filter = {
            ...filter,
            "#or": [{ type: "Document" }, { type: "Contract" }],
            level1Id: { "#ne": null },
          };
        } else if (typeFilter.id === "Document") {
          filter = {
            ...filter,
            type: "Document",
            level1Id: null,
          };
        } else {
          // Add type condition if typeFilter exists and is not "Contract" or "Document"
          filter = {
            ...filter,
            type: typeFilter.id, // Adding the type field correctly
          };
        }
      }

      const sortParam = sort
        ? `${sort.key}:${sort.direction.toUpperCase()}`
        : "updatedAt:DESC";

      const response = await ressourceClient.getAllPaginatedByFilter(
        filter,
        "nested",
        sortParam,
        "primitive",
        currentPage,
        pageSize
      );

      setRessources(response.rows);
      setTotalPages(Math.ceil(response.count / pageSize));
    } catch (error) {
      console.error("Error fetching ressources: ", error);
    } finally {
      setLoading(false);
    }
  }, [
    typeFilter,
    statusFilter,
    searchTerm,
    sort,
    currentPage,
    pageSize,
  ]);


  
  useEffect(() => {
    fetchRessources();
  }, [fetchRessources]);
  

  useEffect(() => {
    fetchRessources();
  }, [fetchRessources]);

  const handleTypeFilterChange = (type) => {
    setTypeFilter(types.find((t) => t.id === type) || null);
    setCurrentPage(1);
  };
  const handleStatusFilterChange = (status) => {
    setStatusFilter(statusList.find((t) => t.id === status) || null);
    setCurrentPage(1);
  };

  const handleSearch = debounce((event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  }, 600);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePageSizeChange = (pageSize) => {
    setCurrentPage(1);
    setPageSize(pageSize);
  };

  const handleLayoutChange = (layout) => {
    setDisplayCards(layout);
  };


  const handleSort = (sortParams) => {
    if (JSON.stringify(sort) !== JSON.stringify(sortParams)) {
      setSort(sortParams);
    }
  };

  return (
    <div style={{ width: "100%", flex: 1 }} className="w-100">
      <section id="ressources" className="pt-2 px-2">
        <span className="current-pathname">{t("pages.ressources.title")}</span>
        <div className="search-zone">
          <div className="ressources-filters">
            <div className="filter-item">
              <CustomCombobox
                canDeselect
                label=""
                onDropdownOpen={() => {}}
                options={types}
                value={typeFilter}
                onChange={handleTypeFilterChange}
                optionValue={(option) => option?.id || null}
                optionDisplay={(option) => (
                  <>
                    {option ? (
                      <div className="filter-type-item">
                        <div className="icon">
                          {option.id === "Form" ? (
                            <img
                              src={formIcon}
                              alt="icon"
                              style={{ width: "30px", height: "30px" }}
                            />
                          ) : option.id === "Contract" ? (
                            <img
                              src={contractIcon}
                              alt="icon"
                              style={{ width: "30px", height: "30px" }}
                            />
                          ) : option.id === "Document" ? (
                            <img
                              src={docIcon}
                              alt="icon"
                              style={{ width: "25px", height: "25px" }}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <span>{option.name}</span>
                      </div>
                    ) : (
                      <span>{t(translationPath + "type")}</span>
                    )}
                  </>
                )}
                fullWidth={true}
                isOpen={openCombobox === "typeFilter"}
                setIsOpen={(isOpen) =>
                  setOpenCombobox(isOpen ? "typeFilter" : null)
                }
              />
            </div>
            <div className="filter-item">
              <CustomCombobox
                canDeselect
                label=""
                onDropdownOpen={() => {}}
                options={statusList}
                value={statusFilter}
                onChange={handleStatusFilterChange}
                optionValue={(option) => option?.id || null}
                optionDisplay={(option) => (
                  <>
                    {option ? (
                      <span>{option.name}</span>
                    ) : (
                      <span>{t("pages.contracts.status")}</span>
                    )}
                  </>
                )}
                fullWidth={true}
                isOpen={openCombobox === "StatusFilter"}
                setIsOpen={(isOpen) =>
                  setOpenCombobox(isOpen ? "StatusFilter" : null)
                }
              />
            </div>
          </div>
          <div className="search-section">
            <div className="search w-75">
              <BsSearch className="search-icon" />
              <input
                type="text"
                placeholder={t("pages.ressources.search")}
                className="search-input"
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
        <LayoutFilter onLayoutChange={handleLayoutChange} />
        <div className="ressources-list">
          {loading ? (
            <Loading height="75vh" />
          ) : displayCards === "grid" ? (
            <RessourcesList
              ressources={ressources}
              reloadRessources={fetchRessources}
            />
          ) : (
            <RessourceTable
              ressources={ressources}
              reloadRessources={fetchRessources}
              onSort={handleSort}
            />
          )}
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          pageSize={pageSize}
        />
      </section>
    </div>
  );
}

export default MyRessources;
