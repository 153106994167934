import { Controller } from "react-hook-form";
import CheckBoxInput from "../CheckBoxInput";
import { useContext } from "react";
import InputValuesContext from "../../../contexts/InputValuesContext";

// ListInputItem component
export function ListInputItem({
  index,
  handleInputChange,
  clauseId,
  subClauseId,
  control,
  fieldName,
  param,
  isEditing,
  editable = true,
  isScrolling,
}:
{
  index: number;
  handleInputChange: (e, paramName, clauseId, subClauseId, fromFormula?, scrollOn?) => void;
  clauseId: any;
  subClauseId?: any;
  control: any;
  fieldName: string;
  param: any;
  isEditing: boolean;
  editable?: boolean;
  isScrolling?: boolean;
}) {
  const listFieldName = `${clauseId}-${subClauseId}-${fieldName}-${param.name}-${index}`;
  const { inputValues } = useContext(InputValuesContext)
  const requiredIndexes = param.args.map((arg, index) => index).filter(index => param.args[index].required)

  const arg = param.args[index]
  const currentValue = inputValues?.[param.name] || [];


  const isOptionChecked = currentValue?.includes(index);


  return (
    <div className="d-flex" style={{padding:"0.1rem"}} key={listFieldName} onClick={(e) => e.stopPropagation()}>
      <Controller
        name={listFieldName}
        control={control}
        defaultValue={isOptionChecked || arg.required}
        render={({ field }) => (
          <CheckBoxInput
            checked={isOptionChecked || field.value}
            name={arg.option}
            onChange={(e) => {
              if(editable){
              field.onChange(e.target.checked);
              const newValue = e.target.checked ?
                [...new Set([...requiredIndexes, ...currentValue ?? [], index])].sort()
                : currentValue?.filter(i => i != index) || requiredIndexes

              handleInputChange(
                { target: { value: newValue } },
                param.name,
                clauseId,
                subClauseId,
                false,
                isScrolling
              );}
            }}
            editable={editable}
            disabled={arg.required}
          />
        )}
      />
    </div>
  );
}
