import React from 'react'
import { EditFormTemplateContext } from "../../../../contexts/EditFormTemplateContext";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { FormListInputItem } from '../../../Form/components/FormListInputItem';
import { FormType } from '../../../../domain/types/FormParams';
const { default: Delete } = require("../../../../assets/delete.svg");

function ListRender({ children, isEditing, param }: {
  children: React.JSX.Element;
  param: FormType.ListParam;
  isEditing: boolean;
}) {
  const { onParamChanged } = React.useContext(EditFormTemplateContext);
  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");
  const translationTypes = "pages.contractTemplateParam.params"
  return (
    <div className={`input-param`}>
      <div className="input-type">{t(`${translationTypes}.${param.type}`)}</div>
      {children}
      <div className="form-template-list-params">
        {param?.args &&
          param?.args?.map((arg, index) => {
            return !isEditing ? (
              <FormListInputItem
                key={index}
                index={index}
                currentValue={[]}
                handleInputChange={() => { }}
                param={param}
                colorLabel="#212529"
              />
            ) : (
              <div className="d-flex w-100 param-list-container">
                <div className="d-flex flex-column w-100 mb-2">
                  <textarea
                    className="custom-form-textarea"
                    style={{ height: "50px" }}
                    value={arg.option}
                    onChange={(e) => {
                      param.args[index].option = e.target.value
                      onParamChanged(param.name, param);
                    }}
                  />
                  <div
                    style={{ marginTop: "10px", gap: "5px" }}
                    className="d-flex"
                  >
                    <input
                      type="checkbox"
                      checked={arg.required}
                      onChange={(e) => {
                        param.args[index].required = e.target.checked
                        onParamChanged(param.name, param);
                      }}

                    />
                    <label>{t(translationPath.list.required)} </label>
                  </div>
                </div>
                <img
                  src={Delete}
                  className="delete-single-option"
                  onClick={() => {
                    param.args.splice(index, 1)
                    onParamChanged(param.name, param);
                  }} />
              </div>
            );
          })}
        {isEditing && (
          <p
            className="add-single-option"
            // style={{ marginLeft: "0px" }}
            onClick={() => {
              param.args.push({
                option: "",
                required: false
              })
              onParamChanged(param.name, param);
            }}
          >
            +
          </p>
        )}
      </div>
    </div>

  )
}

export default ListRender