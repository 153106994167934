import React from 'react'
import EditContractTemplateContext from "../../../../contexts/EditFormTemplateContext";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { FormType } from '../../../../domain/types/FormParams';
import { FormTemplatePageEntity } from '../../../../domain/entities';
const { default: Delete } = require("../../../../assets/delete.svg");

function EnumRender({ children, page, param, isEditing, }: {
  children: React.JSX.Element;
  param: FormType.EnumParam;
  page: FormTemplatePageEntity;
  isEditing: boolean;
}) {
  const { onParamChanged, paramValues, onValueChange } = React.useContext(EditContractTemplateContext);
  const { t, language } = useTranslation();
  const translationTypes = "pages.contractTemplateParam.params"

  const enumOptions = param?.args && param?.args?.map((arg) => arg.option);
  return (
    <div className={`input-param`}>
      <div className="input-type">{t(`${translationTypes}.${param.type}`)}</div>
      {children}
      <div className=" flex-column align-items-start px-3 pb-1 gap-0">
        {enumOptions?.map((option, index) => (
          <div key={index} className="form-check">
            <div style={{ display: isEditing ? "flex" : "" }}>
              {!isEditing ? (
                <>
                  <input
                    type={"radio"}
                    className={"form-check-input"}
                    value={index}
                    checked={paramValues[param.name] !== undefined && paramValues[param.name] === index}
                    onChange={(event) => {
                      const checked = paramValues[param.name] !== undefined && paramValues[param.name] === index
                      if (!checked) {
                        onValueChange(page.id, param, index)
                      }
                    }}
                  />
                </>
              ) : (
                <textarea
                  className="custom-form-textarea"
                  style={{ height: "50px" }}
                  value={option}
                  onChange={(e) => {
                    param.args[index].option = e.target.value
                    onParamChanged(param.name, param);
                  }}
                />
              )}
              {!isEditing ? (
                <label className="form-check-label" htmlFor={String(index)}>
                  {option}
                </label>
              ) : (
                <img
                  alt="img"
                  src={Delete}
                  style={{ marginRight: language == "ar" && "20px" }}
                  className="delete-single-option"
                  onClick={() => {
                    param.args.splice(index, 1)
                    onParamChanged(param.name, param);
                  }}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      {isEditing && (
        <p
          className="add-enum-single-option"
          onClick={() => {
            param.args.push({
              option: ""
            })
            onParamChanged(param.name, param);
          }}
        >
          + {t("pages.contractTemplateParam.edition.enum.option")}
        </p>
      )}
    </div>

  )
}

export default EnumRender