import React, { useState } from "react";
import useApiClientWithLoading from "../../services/api/ApiClient";
import { ContractClient } from "../../services/api/ContractClient";
import { DocumentClient } from "../../services/api/DocumentClient";
import { FormClient } from "../../services/api/FormClient";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../contexts/TranslationProvider";
import { FiMoreHorizontal } from "react-icons/fi";
import ConfirmDeletePopup from "../../components/modals/ConfirmDetelePopup";
import AdvancedTable from "../../components/AdvancedTable";
import { formatDate, getTypeLevels } from "../../helpers/helper";
import form from "../../assets/new-design-icons/Form.svg";
import contract from "../../assets/new-design-icons/Contract.svg";
import ContractCardActionsModal from "../../components/modals/ContractCardActionsModal";
import { toast } from "react-toastify";
import { RessourceEntity } from "../../domain/entities/Ressource";
const docIcon = require('../../assets/new-design-icons/doc.png');


function RessourceTable({
  ressources,
  reloadRessources,
  onSort,
}: {
  ressources: RessourceEntity[];
  onSort?: (sortParams: { key: string; direction: "asc" | "desc" } | null) => void;
  reloadRessources: () => Promise<void>;
}) {
  const navigate = useNavigate();
  const [showConfirmDelete, setConfirmDelete] = useState(false);
  const { t } = useTranslation();
  const formIcon = form.toString();
  const contractIcon = contract.toString();

  const [openActionPopup, setOpenActionPopup] = useState<{
    type: RessourceEntity["type"];
    id: string | number;
  } | null>(null);
  const [ressourceToHandle, setRessourceToHandle] =
    useState<RessourceEntity | null>(null);
  const togglePopup = (e, ress) => {
    e.stopPropagation();
    if (!ress) return setOpenActionPopup(null);
    if (openActionPopup?.type === ress.type && openActionPopup?.id === ress.id)
      return setOpenActionPopup(null);
    setOpenActionPopup({
      type: ress.type,
      id: ress.id,
    });
  };
  const handleClick = (ressource: RessourceEntity) => {
    switch (ressource.type) {
      case "Form":
        return navigate(`/edit-form/${ressource.formId}`);
      case "Contract":
        return navigate(`/edition-contrat/${ressource.contractId}`);
        case "Document":
          if (ressource.level1Id == null)  {
            return navigate(`/document/${ressource.documentId}`);
          } else {
            return navigate(`/document-summary/${ressource.documentId}`);
          }  
    }
  };
  const apiClient = useApiClientWithLoading();
  const handleDelete = async (ressource: RessourceEntity) => {
    const contractClient = new ContractClient(apiClient);
    const documentClient = new DocumentClient(apiClient);
    const formClient = new FormClient(apiClient);
    let deleted = false;
    try {
      switch (ressource.type) {
        case "Contract":
          ({ deleted } = await contractClient.delete(ressource.contractId));
          break;
        case "Form":
          ({ deleted } = await formClient.delete(ressource.formId));
          break;
        case "Document":
          ({ deleted } = await documentClient.delete(ressource.documentId));
      }
      toast.success(t("pages.docxExport.popup.messages.successDelete"));
    } catch (e) {
      toast.error(t("pages.docxExport.popup.messages.errorDelete"));
    }

    if (deleted) {
      await reloadRessources();
    }
  };
  return (
    <section id="contracts-table" className="contracts-table">
      <>
        <AdvancedTable
          hasFilters={false}
          onSort={onSort}
          columns={[
            {
              display: t("pages.projects.tableHeaders.name"),
              accessorKey: "name",
              render: (row) => {
                const displayType = row.ressourceType;
                return (
                  <div className="title-table-item">
                    {displayType === "Form" ? (
                      <img
                        src={formIcon}
                        alt="icon"
                        style={{ width: "30px", height: "30px" }}
                      />
                    ) : displayType === "Contract" || displayType === "ContractAI" ? (
                      <img
                        src={contractIcon}
                        alt="icon"
                        style={{ width: "30px", height: "30px" }}
                      />
                    ) : displayType === "Document" ? (
                      row.level1Id !== null ? (
                        <img
                          src={contractIcon}
                          alt="icon"
                          style={{ width: "30px", height: "30px" }}
                        />
                      ) : (
                        <img
                          src={docIcon}
                          alt="icon"
                          style={{ width: "25px", height: "25px" }}
                        />
                      )
                    ) : (
                      <></>
                    )}
                    <div style={{ marginLeft: "20px" }}>{row.name}</div>
                  </div>
                );
                
              },
              width: 3,
              filtrable: false,
            },
            {
              display: t("pages.projects.tableHeaders.type"),
              accessorKey: "type",
              width: 2,
              filtrable: false,
            },
            {
              display: t("pages.projects.tableHeaders.status"),
              accessorKey: "status",
              width: 2,
              filtrable: false,
              render: (row) => {
                return row.status ? (
                  <div className="status">{row.status}</div>
                ) : (
                  ""
                );
              },
            },
            {
              display: t("pages.projects.tableHeaders.createdAt"),
              accessorKey: "createdAt",
              render: (row) => {
                if (row.createdAt) {
                  return formatDate(row.createdAt);
                }
              },
              width: 2,
              isDate: true,
              filtrable: false,
            },
            {
              display: t("pages.projects.tableHeaders.updatedAt"),
              accessorKey: "updatedAt",
              render: (row) => {
                if (row.updatedAt) {
                  return formatDate(row.updatedAt);
                }
              },
              width: 2,
              isDate: true,
              filtrable: false,
            },
            {
              display: t("pages.projects.tableHeaders.actions"),
              accessorKey: "actions",
              sortable: false,
              filtrable: false,
              width: 1,
            },
          ]}
          originalData={ressources.map((ressource) => ({
            ...ressource,
            status: (ressource.type === "Document" && !ressource.level1Id) 
            ? null 
            : (ressource.status ? t("status." + ressource.status) : ""),
            ressourceType: ressource.type,
            type:
              ressource.type === "Document"
                ? [
                    ressource.level1,
                    ressource.level2,
                    ressource.level3,
                  ]
                    .filter((level) => level !== null)
                    .map((level) => {
                      let levelNamme = null;
                      if (level) {
                        levelNamme = level.name;
                      }
                      return levelNamme;
                    })
                    .join("/")
                : getTypeLevels(ressource)
                    .map((level) => {
                      return level.name;
                    })
                    .join("/"),
            actions: (
              <div className="actions">
                <button
                  className="plus-icon"
                  onClick={(e) => togglePopup(e, ressource)}
                >
                  <FiMoreHorizontal size={20} />
                </button>
                {openActionPopup?.type === ressource.type &&
                  openActionPopup?.id === ressource.id && (
                    <div className="">
                      <ContractCardActionsModal
                        onView={() => handleClick(ressource)}
                        onDelete={() => {
                          setRessourceToHandle(ressource);
                          setConfirmDelete(true);
                        }}
                      />
                    </div>
                  )}
              </div>
            ),
            onDelete: () => {
              setRessourceToHandle(ressource);
              setConfirmDelete(true);
            },
            onEdit: () => {
              handleClick(ressource);
            },
          }))}
        />
      </>
      {showConfirmDelete && ressourceToHandle && (
        <ConfirmDeletePopup
          msgDelete={t("pages.contracts.msgDeleteRessource")}
          onClose={() => {
            setOpenActionPopup(null);
            setConfirmDelete(false);
            setRessourceToHandle(null);
          }}
          entityToDelete={ressourceToHandle?.name || ""}
          onDelete={() => handleDelete(ressourceToHandle)}
        />
      )}
    </section>
  );
}

export default RessourceTable;
