import React, { useEffect, useState } from "react";
import "./ListGroups.scss";
import useApiClientWithLoading from "../../services/api/ApiClient";
import Loading from "../../components/common/Loading";
import GroupInput from "./GroupInput";
import { toast } from "react-toastify";
import * as XLSX from "xlsx"
// import { IGroup, IGroup_User, IUser } from "../types/models";
import { GroupClient } from "../../services/api/GroupClient";
import { UserClient } from "../../services/api/UserClient";
import { useTranslation } from '../../contexts/TranslationProvider';
import FileInput from "../../components/common/FileInput";
import { useNavigate } from "react-router";
import { GroupEntity, Group_UserEntity, UserEntity } from "../../domain/entities";
import { getName } from "../../helpers/Translation";
import AddGroup from "../../components/modals/AddGroup";
import EditGroup from "../../components/modals/EditGroup";
import { AiFillDelete } from "react-icons/ai";
import { set } from "react-hook-form";
import { saveWorkbook } from "../../utils/csv";
const { default: EditIcon } = require("../../assets/edit_icon.svg");

function ListGroups() {
  const { t, language } = useTranslation();
  const [loading, setLoading] = useState(false);
  const apiClient = useApiClientWithLoading(setLoading);
  const groupClient = new GroupClient(apiClient);
  const userClient = new UserClient(apiClient);
  const [showAdd, setShowAdd] = useState(false);
  const [groups, setGroups] = useState<GroupEntity[]>([])
  let [users, setUsers] = useState<UserEntity[]>([])
  const [file, setFile] = useState<File>(null);
  const navigate = useNavigate()
  useEffect(() => {
    groupClient.getAll('full').then(({ rows }) => {
      setGroups(rows);
    });
    userClient.getAll("pure").then(({ rows }) => {
      setUsers(rows);
    });
  }, []);


  const handleSave = async () => {
    setLoading(true)
    try {
      const groupUsers: Group_UserEntity[] = []
      groups.forEach(async group => {
        groupUsers.push(...group.users.map(user => { return { GroupId: group.id, UserId: user.id } }))
        await groupClient.update(group.id, group)
      });

      await groupClient.upsertGroupUsers(groupUsers)
      toast.success(t("modals.listGroups.messages.success"));
    } catch (error) {
      toast.error(error?.message ?? error ?? t("modals.listGroups.messages.error"));
    } finally {
      setLoading(false)
    }
  }
  const handleDelete = async (group: GroupEntity) => {
    setLoading(true)
    try {
      await groupClient.delete(group.id)
      groupClient.getAll('full').then(({ rows }) => {
        setGroups(rows);
      }).then(() => setLoading(false))
    } catch (error) {
      setLoading(false)
    }
  }
  const handleImport = async () => {
    if (file) {
      setLoading(true)
      try {
        await groupClient.import(file)
        toast.success(t("modals.listGroups.messages.importSuccess"));
      } catch (error) {
        toast.error(error?.message ?? error ?? t("modals.listGroups.messages.importError"));
      } finally {
        setLoading(false)
      }
    }
  }
  const handleExport = async () => {
    setLoading(true)
    try {
      const { url } = await groupClient.export()
      const apiUrl = new URL(url);
      const response = await fetch(apiUrl.toString());
      const arrayBuffer = await response.arrayBuffer();
      const data = new Uint8Array(arrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      saveWorkbook(workbook, `GROUPS_USERS.xlsx`);
      toast.success(t("modals.listGroups.messages.importSuccess"));
    } catch (error) {
      toast.error(error?.message ?? error ?? t("modals.listGroups.messages.importError"));
    } finally {
      setLoading(false)
    }
  }
  const handleClosePopup = async (refresh) => {
    setShowAdd(false)
    try {
      if (refresh) {
        setLoading(true)
        await groupClient.getAll('full').then(({ rows }) => {
          setGroups(rows);
        }).then(() => setLoading(false))
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <section className="list-grp-section">
      {
        showAdd && <AddGroup onClose={handleClosePopup} groups={groups} />
      }
      <div style={{ marginBottom: "40px", marginTop: "3px" }}>
        <span>
          <span className='previous-pathname' onClick={() => navigate("/admin")}>
            {t("sidebar.admin")}
          </span>
          <span className="path-separator"> {">"} </span>
        </span>
        <span className='current-pathname'> {t("modals.listGroups.title")}</span>
      </div>
      <div>
        {loading ? (
          <Loading height="50vh" />
        ) : (
          <div className="table-container-groups">
            <table
              className={`fixed-header-table ${language == "ar" && "arabic-table"
                } `}
            >
              <thead>
                <tr>
                  <th>{t("modals.listGroups.code")}</th>
                  <th>{t("modals.listGroups.name")}</th>
                  <th>{t("modals.listGroups.users")}</th>
                  <th>{t("pages.users.actions")}</th>
                </tr>
              </thead>
              <tbody>
                {groups.length > 0 && groups.map((row, rowIdx) => (
                  <GroupInput
                    index={rowIdx}
                    currentValue={row}
                    users={users}
                    handleDeleteGroup={handleDelete}
                    onSave={(newRow) => {
                      setLoading(true)
                      const newRowIdx = groups.findIndex(group => group.id === newRow.id)
                      groups[newRowIdx] = newRow
                      setGroups([...groups])
                      setLoading(false)
                    }}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="actions-list-groups">
        <div className="form-floating mb-4" style={{ width: "60vh" }}>
          <FileInput
            onChange={(file) => setFile(file)}
            value={file}
            borderClassName={file ? "green-border" : "grey-border"}
          />
        </div>
        <div style={{ paddingBottom: "3%", width: "60vh", display: "flex", justifyContent: "space-between", gap: "10px" }}>
          <button type="submit" className="btn-grp" onClick={handleImport}>
            {t("modals.importGroups.import")}
          </button>
          <button type="submit" className="btn-grp" onClick={handleExport}>
            {t("navbar.export")}
          </button>
          <button type="submit" className="btn-grp" onClick={handleSave}>
            {t("modals.listGroups.save")}
          </button>
          <button type="submit" className="btn-grp" onClick={() => setShowAdd(true)}>
            {t("modals.listGroups.add")}
          </button>
        </div>
      </div>
    </section>
  );
}

export default ListGroups;
